
export default class PageHistory {
   clientShortName = "";
   pageHistoryKey = ""

   constructor(clientShortName, api) {
      this.clientShortName = clientShortName;
      this.pageHistoryKey = `hum_${clientShortName}_pageviews`;
      this.api = api;
   }


   trackPageHistory() {
      let pageHistory = localStorage.getItem(this.pageHistoryKey);
      const newPage = {
         source: this.api.contentSource,
         content_id: this.api.getContentId(this.api.getUrl()),
         url: this.api.getUrl(),
      }

      if (!pageHistory || pageHistory === 'null') {
         localStorage.setItem(this.pageHistoryKey, JSON.stringify([newPage]));
         return;
      }

      let parsedPH = JSON.parse(pageHistory);
      const existingPageIndex = parsedPH.findIndex(item =>
         item.source === newPage.source &&
         item.content_id === newPage.content_id &&
         item.url === newPage.url
      );

      if (existingPageIndex === -1) {
         parsedPH.unshift(newPage);
      } else {
         parsedPH.splice(existingPageIndex, 1);
         parsedPH.unshift(newPage);
      }

      if (parsedPH.length > 30) {
         parsedPH.pop();
      }

      localStorage.setItem(this.pageHistoryKey, JSON.stringify(parsedPH));
   }

   getPageHistory() {
      return JSON.parse(localStorage.getItem(this.pageHistoryKey));
   }
}
import {matchPattern} from './rule';
import feature from './feature';
import {setCookie} from "./cookie";

function getAdCampaigns(tracker) {
   if (tracker.featureFlags[feature.Sc18435]) {
      return tracker.settings.adTargeting.campaigns.filter(c => !c.segment || tracker.personalizationData.segments.includes(c.segment));
   } else {
      const {campaigns} = tracker.campaigns;
      if (Array.isArray(campaigns)) {
         return campaigns.filter(c => c.template === 'advertisement');
      }
   }
   return [];
}

function bindAdSlotClick(tracker, adSlot) {
   const listener = () => {
      if (document.activeElement === document.getElementById('google_ads_iframe_' + adSlot.getSlotId().getId())) {
         sendAdEvent(tracker, 'ad-click', adSlot);
         window.removeEventListener('blur', listener);
      }
   };
   window.removeEventListener('blur', listener);
   window.addEventListener('blur', listener);
}

function bindAdViewableImpression(tracker) {
   window.googletag.pubads().addEventListener('impressionViewable', function (event) {
      if (!event.isEmpty && event.slot) {
         sendAdEvent(tracker, 'ad-impression-viewable', event.slot);
      }
   });
}

function sendAdEvent(tracker, eventName, adSlot) {
   const data = {ad_source: 'gam'};
   const response = adSlot.getResponseInformation();

   for (const name in response) {
      if (Object.hasOwn(response, name) && response[name] && response[name]) {
         data[name] = response[name].toString();
      }
   }

   window.googletag.pubads().getTargetingKeys().forEach(key => {
      data['targeting-' + key] = window.googletag.pubads().getTargeting(key);
   });

   // prepare event data and send ad-level event
   const eventData = tracker.api.prepareEventData(eventName, data);
   eventData.meta.page_source = eventData.source;
   eventData.meta.page_content_id = eventData.content_id;
   eventData.source = 'gam';
   eventData.content_id = response?.creativeId.toString() || '';

   tracker.api.sendRawEvent(eventData);
}

function bindSlotRenderEnded(tracker) {
   window.googletag.pubads().addEventListener('slotRenderEnded', function (event) {
      if (!event.isEmpty && event.slot) {
         bindAdSlotClick(tracker, event.slot);
      }

      if (tracker.featureFlags[feature.Sc12738]) {
         // Check if the slot is an OutOfPage ad and has a response
         if (event.slot && event.slot.getOutOfPage() && event.slot.getResponseInformation() != null) {
            disableLeadGenPopups(tracker);
         }
      }
   });
}

function disableLeadGenPopups(tracker) {
   tracker.disablePopups();
}


function isInView(elem) {
   var bounding = elem.getBoundingClientRect();
   return (
      bounding.top >= 0 &&
      bounding.left >= 0 &&
      bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
   );
}


export default class GoogleAdManager {
   /**
    * @param {Tracker} tracker
    */
   constructor(tracker) {
      if (!tracker) {
         return console.error('Tracker not provided.');
      }

      this.tracker = tracker;
      this.run();
   }

   run() {
      let tracker = this.tracker;

      const adPersonalizationKey = `hum_${tracker.clientShortName}_ad_personalization`

      let campaigns = getAdCampaigns(this.tracker);
      let campaignsMatched = false;
      let allTargets = {};


      campaigns.forEach(c => {
         if (matchPattern(window.location.href, c.url_patterns) && (!c.excluded_urls || !matchPattern(window.location.href, c.excluded_urls))) {
            campaignsMatched = true;

            // Configure page-level targeting.
               const targeting = c.targeting ?? {};

               Object.keys(targeting).forEach(key => {
                  if (!targeting[key].length) return;


                  if (typeof (allTargets[key]) === "undefined" || allTargets[key] === null) {
                     allTargets[key] = []
                  }

                  if (Array.isArray(targeting[key])) {
                     targeting[key].forEach(v => allTargets[key].push(v));
                  } else {
                     allTargets[key].push(targeting[key]);
                  }
               });

         }
      });

      localStorage.setItem(adPersonalizationKey, JSON.stringify(allTargets))

      if (typeof window.googletag !== 'undefined' && window.googletag) {
         let refreshRequired = false;

         window.googletag.cmd.push(function () {

            bindSlotRenderEnded(tracker);

            if (tracker.featureFlags[feature.Sc12738]) {
               // Check existing slots for OutOfPage ads after registering the event listener
               for (const slot of window.googletag.pubads().getSlots()) {
                  if (slot.getOutOfPage() && slot.getResponseInformation() != null) {
                     disableLeadGenPopups(tracker);
                     break;
                  }
               }
            }

            Object.keys(allTargets).forEach(key => {
               let currentTargeting = window.googletag.pubads().getTargeting(key);

               if (currentTargeting.length !== allTargets[key].length || !currentTargeting.every((v, i) => v===allTargets[i])) {
                  window.googletag.pubads().setTargeting(key, allTargets[key]);
                  refreshRequired = true;
               }
            })

            if (tracker.featureFlags[feature.Sc9199_1]) {
               bindAdViewableImpression(tracker);
            }

            if (campaignsMatched && window.googletag.pubads().getSlots().length > 0) {
               const refreshImmediately = [];
               const refreshOnDelay = [];
               for (const s of window.googletag.pubads().getSlots()) {
                  bindAdSlotClick(tracker, s);

                  if (refreshRequired) {
                     //ad slots loaded before our tags, refresh them
                     if (!s.getResponseInformation()) {
                        //no ad rendered, see if it was attempted
                        if (document.getElementById(s.getSlotId().getDomId()).querySelector('*')) {
                           //if the adslot has a child element, google attempted to fill the ad but could not
                           refreshImmediately.push(s);
                        }
                     } else {
                        if (isInView(document.getElementById(s.getSlotId().getDomId()))) {
                           //since slots were rendered before our code fired, any visible slots with an Ad, we'll record a viewable event for
                           sendAdEvent(tracker, 'ad-impression-viewable', s);
                        }
                        //after refreshExistingImpressionSecond sec if ad was rendered so the current impression has time to be viewed
                        refreshOnDelay.push(s);
                     }
                  }
               }
               if (tracker.settings.adTargeting.refreshAdSlots && refreshRequired) {
                  if (refreshImmediately.length > 0) {
                     window.googletag.pubads().refresh(refreshImmediately, {changeCorrelator: false});
                  }
                  if (refreshOnDelay.length > 0) {
                     setTimeout(() => window.googletag.pubads().refresh(refreshOnDelay, {changeCorrelator: false}), tracker.settings.adTargeting.refreshExistingImpressionSeconds * 1000);
                  }
               }

            }
         });

      }
   }
}

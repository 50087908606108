import {matchPattern} from './rule';
import feature from "./feature";

export const SET_MAIN_RECOMMENDATIONS = 'set_main_recommendations';
export const SET_FUNCTIONAL_RECOMMENDATIONS = 'set_functional_recommendations';

export async function applySettings(tracker) {
   try {
      let { settings, trackerOptions, api, featureFlags } = tracker;

      if (!settings || !trackerOptions) {
         return;
      }

      settings = tryReplaceSourceSettings(settings, trackerOptions);


      if (settings.recommendations) {
         await configureRecommendations(tracker);
      }

      let ignoreScrollEvents = false;
      if (settings?.conditionalEvents?.length > 0) {
         for (const conditionalEvent of settings.conditionalEvents) {
            const $el = document.querySelector(conditionalEvent.selector);
            if ($el) {
               if (!ignoreScrollEvents) {
                  ignoreScrollEvents = !!conditionalEvent?.ignoreScrollEvents;
               }
               if (conditionalEvent?.event) {
                  trackerOptions.sendWithPageView(conditionalEvent.event);
               }
            }
         }
      }

      if (!ignoreScrollEvents && settings?.scrollElements?.length > 0) {
         const $page = document.querySelector(settings.scrollElements);
         trackerOptions.trackScroll($page, 'post read');
      }


      if (settings.clicksToTrack && settings.clicksToTrack.length > 0) {
         settings.clicksToTrack.forEach(toTrack => {
            const $elements = document.querySelector(toTrack.selector);
            trackerOptions.trackClick($elements, toTrack.event);
         });
      }

      parseForms(settings);

      if (settings.forms) {
         bindHumForms(settings.forms, trackerOptions);

         var observer = new MutationObserver(function (mutations) {
            mutations.forEach(function (mutation) {
               mutation.addedNodes.forEach(function (node) {
                  //rebind all forms on the page
                  if (node.outerHTML && node.outerHTML.indexOf('<input') !== -1) {
                     bindHumForms(settings.forms, trackerOptions);
                  }
               });
            });
         });
         observer.observe(document.body, {childList: true, subtree: true});
      }

      if (settings.contentIdParams && Array.isArray(settings.contentIdParams)) {
         api.setContentIdParams(settings.contentIdParams);
      }

      parseKeywords(api, settings.keywordsConfig)

      return settings;
   } catch (error) {
      console.error('Unable to apply settings.', error);
   }
}

async function configureRecommendations(tracker) {
   const { settings, trackerOptions, api, pageHistory, visitorId, featureFlags } = tracker;

   const check = window[SET_FUNCTIONAL_RECOMMENDATIONS];
   if (check) {
      // the functional beacon already set the recommendations
      return;
   }

   window[SET_MAIN_RECOMMENDATIONS] = true;
   let recommendationsToShow = [];
   for (const recOpt of settings.recommendations) {
      if (recOpt.css_selector && !recOpt.archived) {
         let $widgetElements = document.querySelectorAll(recOpt.css_selector);
         if ($widgetElements.length > 0) {
            recommendationsToShow.push({elements: $widgetElements, list_id: recOpt.list_id})
         }
      }
   }

   for (const rec of recommendationsToShow) {
      try {
         let resp;
         if (featureFlags[feature.Sc13600]) {
            resp = await api.getContentRecommendationWidgetHtml(visitorId, rec.list_id, pageHistory.getPageHistory());
         } else {
            resp = await api.getRecommendationWidgetHtml(rec.list_id);
         }
         if (resp) {
            rec.elements.forEach($widgetEl => {
               const placeholderElement = document.createElement('div');
               placeholderElement.innerHTML = resp.markup;
               $widgetEl.appendChild(placeholderElement);
               if (featureFlags[feature.Sc13767]) {
                  trackerOptions.trackRecommendationView(placeholderElement, rec.list_id);
                  trackerOptions.trackRecommendationClick(placeholderElement, rec.list_id);
               }
            });
         }
      } catch (error) {
         console.error('failed creating recommendation ' + rec.list_id);
      }
   }
}

function tryReplaceSourceSettings(settings, tracker) {
   const sourceSettings = settings?.sources?.[tracker.contentSource];
   if (sourceSettings) {
      return {...settings, ...sourceSettings};
   }
   return settings;
}

function parseKeywords(api, keywordsConfig) {
   if (!keywordsConfig) return;

   keywordsConfig.forEach(keyword => {
      if (!keyword.selector) return;

      if (matchPattern(window.location.href, keyword.patterns)) {
         api.addKeywordSelector(keyword.selector);
      }
   });
}

function bindHumForms(humForms, tracker) {
   humForms.forEach(humForm => {
      let $formElement = getElementToBind(humForm);

      if ($formElement) {
         bindHumForm(humForm, $formElement, tracker);
      }
   });
}

function getElementToBind(humForm) {

   if (humForm.allowedHostnames &&
      humForm.allowedHostnames.length > 0 &&
      !humForm.allowedHostnames.includes(window.location.host)) {
      //if the allowed hosts property is set and the current host isn't in
      //the list of allowed hosts, skip wiring up this form
      return null;
   }

   return document.body.querySelector(humForm.documentFormSelector);
}

function bindHumForm(humForm, $formElement, tracker) {
   if (humForm == null || $formElement == null) {
      return;
   }

   try {
      let event = 'submit';
      let $bindElement = $formElement;
      if (humForm.bindElementSelector) {
         //this is not a basic form, reset the event and bindElement
         event = humForm.bindEvent;
         $bindElement = document.querySelector(humForm.bindElementSelector);
      }

      if ($bindElement) {
         let listener = tracker.getFormListener(humForm, $formElement);
         if (!$bindElement.getAttribute(humForm.humFormId)) {
            $bindElement.setAttribute(humForm.humFormId, 'set');
            $bindElement.addEventListener(event, listener);
         }
      }

   } catch (error) {
      console.warn('Unable to configure ' + humForm._friendlyName + '.', error);
   }
}

function parseForms(settings) {
   const definedForms = new Set();
   const ignoredTypes = new Set(['password', 'file', 'image', 'reset', 'button', 'hidden']);

   if (document.location.pathname.endsWith('.aspx') || document.getElementById('__VIEWSTATE')) {
      return;
   }

   settings.forms = settings.forms || [];
   settings.ignoredSelectors = settings.ignoredSelectors || [];
   settings.forms.forEach(form => {
      definedForms.add(form.documentFormSelector);
   });

   [].forEach.call(document.forms, form => {
      const formId = form.getAttribute("id");
      const documentFormSelector = '#' + formId;
      if (!formId || definedForms.has(documentFormSelector)) {
         return;
      }

      const singleValueFieldSelectors = {};
      const multiValueFieldSelectors = {};
      const trueIfExistsFieldSelectors = {};

      let fieldsetIdx = 0;
      let fieldset = null;
      let legend = '';

      [].forEach.call(form.elements, el => {
         if (settings.ignoredSelectors.some(sel => form.querySelector(sel) === el)) {
            return;
         }

         const selector = generateSelector(form, el);
         let label = getLabel(el);

         if (fieldset && !fieldset.contains(el)) {
            fieldset = null;
            legend = '';
         }

         switch (el.tagName.toLowerCase()) {
            case 'input':
               if (ignoredTypes.has(el.type)) return;

               switch (el.type) {
                  case 'checkbox':
                  case 'radio':
                     if (fieldset) {
                        label = legend || label;
                        multiValueFieldSelectors[label] = `fieldset:nth-of-type(${fieldsetIdx}) input:checked`;
                        break;
                     }
                     trueIfExistsFieldSelectors[label] = selector + ':checked';
                     break;
                  default:
                     singleValueFieldSelectors[label] = selector;
               }
               break;
            case 'select':
               multiValueFieldSelectors[label] = selector + ' option:checked';
               break;
            case 'textarea':
               singleValueFieldSelectors[label] = selector;
               break;
            case 'fieldset':
               fieldsetIdx++;
               fieldset = el;
               legend = [].find.call(el.children, el => el.tagName.toLowerCase() === 'legend');
               if (legend) {
                  legend = purify(legend.textContent).replace(/ /g, '_').toLowerCase();
               }
               break;
         }

      });

      settings.forms.push({
         _friendlyName: 'Parsed form: ' + documentFormSelector,
         eventType: 'form',
         humFormId: formId,
         documentFormSelector,
         singleValueFieldSelectors,
         multiValueFieldSelectors,
         trueIfExistsFieldSelectors,
      });
   });
}

function getLabel(el) {
   if (el.labels && el.labels.length) {
      return [].reduce.call(el.labels, (acc, label) => {
         acc.push(purify(label.textContent).replace(/ /g, '_'));
         return acc;
      }, []).join('_').toLowerCase();
   }

   return el.name || el.id;
}

function generateSelector(form, el) {
   if (el.id) return '#' + el.id;

   let path = [], parent;
   while (true) {
      parent = el.parentNode
      path.unshift(`${el.tagName}:nth-child(${[].indexOf.call(parent.children, el) + 1})`);
      if (parent === form) break;
      el = parent;
   }

   return `${path.join(' > ')}`.toLowerCase();
}

function purify(text) {
   return text.replace(/[.,\/#!$%^&*;:{}=\-_`~()]/g, '').trim();
}

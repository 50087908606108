
export function isExpired(key) {
   const time = localStorage.getItem(key);
   if (!time) {
      return true;
   }
   const expiresAt = new Date(time);
   return Date.now() > expiresAt.getTime();
}

export function setExpirationAfter(key, seconds) {
   const d = new Date(Date.now() + (1000 * seconds));
   const expiresAt = d.toString();
   localStorage.setItem(key, expiresAt);
}
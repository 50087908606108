const createAndWaitLoadIframe = async (url, customAttributesFlag, iframeAttributes) => {
   return new Promise(res => {
      const iframe = document.createElement('iframe');
      iframe.src = url;
      iframe.style.cssText = 'height: 0; width: 0; opacity: 0';
      if (customAttributesFlag) {
         for (const [key, value] of Object.entries(iframeAttributes)) {
            iframe.setAttribute(key, value);
         }
      }
      iframe.onload = () => res(iframe);
      iframe.onerror = (e) => {
         console.warn('failed to load ls iframe: ', e);
         res(false);
      };
      document.body.appendChild(iframe);
   });
};

const sendAndWaitResp = async (msgObj, el, origin) => {
   const resp = new Promise(resolve => {
      const respFn = (e) => {
         if (e.origin === origin && e.data) {
            const { res, key, val } = e.data;
            if (key !== msgObj.args.key) return;

            window.removeEventListener('message', respFn, false);
            if (res === 'ok') {
               resolve(val);
            } else {
               resolve(false);
            }
         }
      };
      window.addEventListener('message', respFn, false);
      el.contentWindow.postMessage(msgObj, origin);
   });
   const to = new Promise(res => setTimeout(() => res(false), 1000));
   return Promise.race([resp, to]);
};

const setVal = async (key, id, el, origin) => {
   const obj = {
      cmd: 'set',
      args: {
         key,
         val: id,
      },
   };
   return await sendAndWaitResp(obj, el, origin);
};

const getVal = async(key, el, origin) => {
   const obj = {
      cmd: 'get',
      args: {
         key,
      },
   };
   return await sendAndWaitResp(obj, el, origin);
};

const clrVal = async(key, el, origin) => {
   const obj = {
      cmd: 'clr',
      args: {
         key,
      },
   };
   return await sendAndWaitResp(obj, el, origin);
};

export const getVisitorId = async (url, key, customAttributesFlag, iframeAttributes) => {
   if (!url) {
      console.warn('iframe URL is not provided');
      return false;
   }

   const origin = new URL(url).origin;
   const iframe = await createAndWaitLoadIframe(url, customAttributesFlag, iframeAttributes);
   if (!iframe) {
      return false;
   }
   let id = await getVal(key, iframe, origin);

   iframe.remove();

   if(!id){
      return null;
   }

   return id;
};

export const setVisitorId = async (id, url, key, customAttributesFlag, iframeAttributes) => {
   if (!url) {
      console.warn('iframe URL is not provided');
      return false;
   }

   const origin = new URL(url).origin;
   const iframe = await createAndWaitLoadIframe(url, customAttributesFlag, iframeAttributes);
   if (!iframe) {
      return false;
   }
   const ok = await setVal(key, id, iframe, origin);
   iframe.remove();
   return ok;
};

export const clearVisitorId = async (url, key, customAttributesFlag, iframeAttributes) => {
   if (!url) {
      console.warn('iframe URL is not provided');
      return false;
   }

   const origin = new URL(url).origin;
   const iframe = await createAndWaitLoadIframe(url, customAttributesFlag, iframeAttributes);
   if (!iframe) {
      return false;
   }
   const ok = await clrVal(key, iframe, origin);
   iframe.remove();
   return ok;
};
import feature from "./feature";

const META_FIELDS = ['tags', 'keywords', 'categories', 'title', 'content_type', 'journal_title'];
const MAX_STRING_LENGTH = 2000;

/*global API_URL, SOURCE*/
export default class Api {
   apiUrl;
   apiKey;
   visitorId;
   userId;
   sid;
   contentSource;
   contentIdParams;
   humInfo;
   keywordSelectors = [];

   constructor() {
      this.apiUrl = API_URL;
      this.contentSource = SOURCE || '';
      this.contentIdParams = [];
      this.humInfo = window.humInfo || {}; //setting humInfo from window will be deprecated in a future release, but this is the quickest way to fix the bug in the hum blog
      window.previousHumRequests = window.previousHumRequests || [];
   }

   /**
    * @param {string|Array} eventName
    * @param {number|optional} eventValue
    * @param {object|optional} eventData
    */
   async sendEvent(eventName, eventValue, eventData) {
      let eventNames = [];
      let events = [];
      if (!Array.isArray(eventName)){
         eventNames.push(eventName);
      } else {
         eventNames = eventName;
      }

      eventNames.forEach(e => {
         this.prepHumInfoFromScholarlyContent(e);

         const data = this.prepareEventData(e, eventValue, eventData)
         events.push(data);
      });

      return this.sendRawEvents(events);
   }

   async sendRawEvent(event) {
      let events = [];
      events.push(event);
      return this.sendRawEvents(events);
   }

   async sendRawEvents(events) {
      if (!this.isValidHttpUrl(window.location.href)) {
         return
      }

      // if a browser tab was collected events to queue
      // and was closed before send the events then send them here.
      if (this.visitorId) {
         this.sendEventsFromQueue();
      }

      // is there is still no visitor id, push the event to the queue,
      // wait for the visitor id and send the events from the queue.
      events.forEach(e => {
         if (!e.visitor_id) {
            if (this.visitorId) {
               e.visitor_id = this.visitorId;
            } else {
               this.pushEventToQueue(e);
               const int = setInterval(() => {
                  if (this.visitorId) {
                     this.sendEventsFromQueue();
                     clearInterval(int);
                  }
               }, 1000)
               return true;
            }
         }
      });

      const eventURL = this.buildUrl('event/batch', this.apiKey);
      const res = await this.makeRequest('POST', eventURL, { events: events });
      if (res.statusCode !== 200) {
         throw new Error(`Request for ${eventURL} failed with code ${res.statusCode}`);
      }

      return res.result;
   }

   prepareEventData(eventName, eventValue, eventData) {
      const url = this.getUrl();

      const data = {
         visitor_id: this.visitorId,
         event: this.prepareEvent(eventName),
         tab_id: window.name.slice(0, MAX_STRING_LENGTH),
         url: url,
         meta: this.getGlobalMetaValue(eventName),
         platform: 'web',
      };

      if (this.userId) {
         data.user_id = this.userId;
      }

      if (this.sid) {
         data.user_sid = this.sid;
      }

      if (typeof eventData == 'undefined' && typeof eventValue == 'object') {
         eventData = eventValue;
      } else if (typeof eventValue == 'number') {
         data.value = eventValue;
      }

      data.content_id = this.getContentId(url);
      data.source = this.contentSource;

      if (typeof eventData == 'object') {
         if (eventData.sid) {
            data.user_sid = eventData.sid;
            delete eventData.sid;
         }

         const keys = Object.getOwnPropertyNames(eventData);
         keys.forEach(function (name) {
            data.meta[name] = eventData[name];
         });
      }

      const keywords = this.getKeywords(data);
      if (keywords) {
         data.meta.keywords = keywords;
      }

      return data
   }

   pushEventToQueue(data) {
      let queue = this.getQueue();
      queue.push(data);
      this.setQueue(queue);
   }

   sendEventsFromQueue() {
      const queue = this.getQueue();
      this.setQueue([]);
      queue.forEach((event) => {
         event.visitor_id = this.visitorId;
         this.makeRequest('POST', this.buildUrl('event/batch', this.apiKey), {"events": [event]});
      });
   }

   getQueue() {
      const queue = localStorage.getItem('events');
      if (queue) {
         return JSON.parse(queue);
      }
      return [];
   }

   setQueue(queue) {
      localStorage.setItem('events', JSON.stringify(queue));
   }

   prepHumInfoFromScholarlyContent(eventName) {
      try {
         if (eventName === 'pageview') {
            const $jsonLdElement = document.querySelector("script[type='application/ld+json']");
            if ($jsonLdElement && $jsonLdElement.innerText) {
               const jsonLd = JSON.parse($jsonLdElement.innerText);


               if (!this.humInfo.title) {
                  this.humInfo.title = jsonLd.name;
               }

               if (!this.humInfo.image) {
                  this.humInfo.image = jsonLd.thumbnailURL || jsonLd.image;
               }

               if (!this.humInfo.description) {
                  this.humInfo.description = jsonLd.description;
               }
            }
         }
      } catch (error) {
         console.error(error);
      }

      this.humInfo = this.humInfo || {};



      if (this.isPageviewEvent(eventName)  && !document.firstElementChild.classList.contains('translated-ltr')) {
         //prep the rest of the metadata only for pageview events
         if (!this.humInfo.title) {
            this.humInfo.title = this.getMetaContent('citation_title');
         }

         if (!this.humInfo.journal_title) {
            this.humInfo.journal_title = this.getMetaContent('citation_journal_title');
         }

         if (document.querySelector('[data-sitename]')) {
            if (!this.humInfo.content_type) {
               if (window.location.pathname.indexOf('/article') !== -1) {
                  this.humInfo.content_type = 'journal_article';
               } else if (window.location.pathname.indexOf('/chapter') !== -1) {
                  this.humInfo.content_type = 'book_chapter';
               } else if (window.location.pathname.indexOf('/search-results') !== -1) {
                  this.humInfo.content_type = 'search';
               } else if (window.location.pathname.indexOf('/issue/') !== -1) {
                  this.humInfo.content_type = 'issue';
               } else if (window.location.pathname.indexOf('/book/') !== -1) {
                  this.humInfo.content_type = 'book';
               } else if (window.location.pathname.indexOf('/my-account/') !== -1) {
                  this.humInfo.content_type = 'account_management';
               } else if (window.location.pathname.indexOf('/pages/') !== -1) {
                  this.humInfo.content_type = 'self-serve';
               } else if (window.location.pathname.indexOf('/crossref-citedby/') !== -1) {
                  this.humInfo.content_type = 'cross-ref-citation';
               } else if (window.location.pathname.substring(1).indexOf('/') === -1) {
                  this.humInfo.content_type = 'microsite_home';
               }
            }
         }
      }
   }

   isPageviewEvent(eventName) {
      if (eventName === 'pageview') {
         return true;
      }

      if (Array.isArray(eventName) || Array.prototype.indexOf('pageview') !== -1) {
         return true;
      }

      return false;
   }

   async receiveVisitorId(params) {
      let path = 'visitor';
      if (this.userId) {
         path += '?uid=' + this.userId;
      } else if (this.sid) {
         path += '?sid=' + this.sid;
      }
      const result = await this.makeRequest('POST', this.buildUrl(path, this.apiKey), params);
      if (result.statusCode !== 200) {
         throw new Error(`Request for ${path} failed with code ${result.statusCode}`);
      }

      if (result.result.id === undefined) {
         throw 'visitor id undefined';
      }

      return result.result.id;
   }

   async getCampaigns(visitorId) {
      const reqUrl = `tracker/campaigns?vid=${visitorId}`;
      const res = await this.makeRequest('GET', this.buildUrl(reqUrl, this.apiKey));
      if (res.statusCode !== 200) {
         throw new Error(`Request for ${reqUrl} failed with code ${res.statusCode}`);
      }
      return res.result;
   }

   async getPersonalizationData(visitorId) {
      const reqUrl = `tracker/personalization/${visitorId}`;
      const res = await this.makeRequest('GET', this.buildUrl(reqUrl, this.apiKey));
      if (res.statusCode !== 200) {
         throw new Error(`Request for ${reqUrl} failed with code ${res.statusCode}`);
      }
      return res.result;
   }

   async getCampaignPreview(linkId) {
      const reqUrl = `tracker/campaigns/preview/${linkId}`;
      const res = await this.makeRequest('GET', this.buildUrl(reqUrl, this.apiKey));
      if (res.statusCode !== 200) {
         throw new Error(`Request for ${reqUrl} failed with code ${res.statusCode}`);
      }
      return res.result;
   }

   async getSettings(beaconKey) {
      try {
         // `beaconKey` is used to differentiate the 2 beacons, otherwise the `makeRequest` func will return an empty Promise
         // for one of them because of the `window.previousHumRequests` check
         const reqUrl = `tracker/settings?beaconKey=${beaconKey}`;
         let res = await this.makeRequest('GET', this.buildUrl(reqUrl, this.apiKey));
         if (res.statusCode !== 200) {
            if (res.statusCode === 404) {
               try {
                  let reqUrl;
                  let urlPostfix = 'settings/' + this.clientShortName + '_settings.json';
                  if (window.hum && window.hum.sdkUrl) {
                     const url = new URL(window.hum.sdkUrl);
                     reqUrl = url.origin + url.pathname.replace('main.js', urlPostfix);
                  } else {
                     //derive from api url
                     reqUrl = `${new URL(this.apiUrl).origin}/js/${urlPostfix}`;
                  }

                  res = await this.makeRequest('GET', reqUrl);
                  if (res.statusCode !== 200) {
                     console.warn(`Request for ${reqUrl} failed with code ${res.statusCode}`);
                  }
                  return res.result;

               } catch (error) {
                  console.warn('could not download hum settings', error);
                  return null;
               }
            } else {
               console.warn('failed to get tracker settings, error code: ', res.statusCode);
               return null;
            }
         }
         return res.result;
      } catch (e) {
         console.warn('failed to get tracker settings: ', e.message);
         return null;
      }
   }

   async mergeVisitorsById(src, dst) {
      try {
         const reqUrl = `visitor-merge?src=${src}&dst=${dst}`;
         const res = await this.makeRequest('POST', this.buildUrl(reqUrl, this.apiKey));
         if (res.statusCode !== 200) {
            console.warn(`Request for ${reqUrl} failed with code ${res.statusCode}`);
         }
         return res.result;
      } catch (error) {
         console.warn('could not merge visitors', error);
      }
   }

   async sendVisitorMergeEvent(src, dst) {
      const eventURL = this.buildUrl('event/batch', this.apiKey)
      const data = this.prepareEventData("visitor-merge", {src});
      data.visitor_id = dst;

      const res = await this.makeRequest('POST', eventURL, {"events": [data]});
      if (res.statusCode !== 200) {
         throw new Error(`Request for ${eventURL} failed with code ${res.statusCode}`);
      }

      return res.result;
   }

   async getRecommendationWidgetHtml(listId) {
      const reqUrl = `tracker/recommendation_widget/${listId}/${this.visitorId}`
      const res = await this.makeRequest('GET', this.buildUrl(reqUrl, this.apiKey));
      if (res.statusCode !== 200) {
         if (res.statusCode === 404) {
            return
         }
         throw new Error(`Request for ${reqUrl} failed with code ${res.statusCode}`);
      }
      return res.result;
   }

   async getContentRecommendationWidgetHtml(visitorId, listId, pageHistory = null) {
      let reqUrl = this.buildUrl('tracker/recommendation_widget', this.apiKey);
      const contentId = this.getContentId(this.getUrl());

      let data;
      const currentPage = {source: this.contentSource, content_id: contentId, url: this.getUrl()};
         data = {
            list_id: listId,
            visitor_id: this.visitorId,
            current_page: currentPage,
            page_history: pageHistory,
         };

      const res = await this.makeRequest('POST', reqUrl, data);
      if (res.statusCode !== 200) {
         if (res.statusCode === 404) {
            return
         }
         throw new Error(`Request for ${reqUrl} failed with code ${res.statusCode}`);
      }
      return res.result;
   }

   async getWidgetPreview(linkId) {
      const reqUrl = `tracker/recommendation_widget/preview/${linkId}`;
      const res = await this.makeRequest('GET', this.buildUrl(reqUrl, this.apiKey));
      if (res.statusCode !== 200) {
         throw new Error(`Request for ${reqUrl} failed with code ${res.statusCode}`);
      }
      return res.result;
   }

   setApiUrl(url) {
      this.apiUrl = url;
   }

   setClientShortName(csn) {
      this.clientShortName = csn;
   }

   setVisitorId(id) {
      this.visitorId = id;
   }

   setApiKey(key) {
      this.apiKey = key;
   }

   setUserId(id) {
      if (id) {
         this.userId = id.toLowerCase().trim();
      } else {
         this.userId = '';
      }
   }

   setSID(id) {
      this.sid = id;
   }

   setContentSource(source) {
      this.contentSource = source;
   }

   setContentIdParams(contentParams) {
      if (Array.isArray(contentParams)) {
         this.contentIdParams = contentParams;
         return;
      }

      console.warn('settings: contentIdParams should be an array');
   }

   async makeRequest(method, url, data) {
      const key = data ? url + JSON.stringify(data) : url;
      if (window.previousHumRequests.indexOf(key) !== -1) {
         return new Promise(resolve => {
            resolve({result: {}, statusCode: 200});
         });
      }

      window.previousHumRequests.push(key);
      const response = await fetch(url, {
         method: method,
         cache: 'no-cache',
         headers: {
            'Content-Type': 'application/json',
         },
         redirect: 'follow',
         body: data ? JSON.stringify(data) : null,
      });

      const text = await response.text();
      try {
         const json = JSON.parse(text);
         return new Promise(resolve => {
            resolve({result: json, statusCode: response.status});
         });
      } catch (err) {
         return new Promise(resolve => {
            resolve({statusCode: response.status});
         });
      }
   }

   buildUrl(path, key) {
      let url = this.apiUrl.replace('/api', '/events/api') + path;
      const keyQuery = '_kid=' + key;
      if (url.indexOf('?') >= 0) {
         url += '&' + keyQuery;
      } else {
         url += '?' + keyQuery;
      }
      return url;
   }

   getGlobalMetaValue(eventName) {
      const ogProperties = ['title', 'description', 'image'];
      const result = {
         referer: window.document.referrer,
      };

      //for pageview events, track more data
      if (eventName === 'pageview') {
         if (window.location.pathname === '/') {
            this.humInfo.content_type = 'homepage';
         }

         if (this.humInfo) {
            const self = this;
            const keys = Object.getOwnPropertyNames(this.humInfo);
            keys.forEach(function (name) {
               if (META_FIELDS.indexOf(name) >= 0) {
                  result[name] = self.humInfo[name];
               }
            });
         }

         const self = this;
         ogProperties.forEach(function (prop) {
            if (!result[prop]) {
               let value = self.getOgContent(prop);
               if (value) {
                  result[prop] = value;
               }
            }
         });

         if (!result['title']) {
            //no og title present, use the page title
            let title = this.getParsedTitle();
            if (title) {
               result['title'] = title;
            }
         }

         if (!result['description']) {
            result['description'] = this.getMetaContent('description');
         }

      }
      return result;
   }

   getParsedTitle() {
      let titleElement = document.querySelector('title');
      if (!titleElement) {
         return;
      }

      //skip parsing the title as different client sites are too inconsistent
      // let pipeIndex = titleElement.text.indexOf('|');
      // if (pipeIndex > -1 && pipeIndex != titleElement.text.length - 1) {
      //    return titleElement.text.substring(0, pipeIndex).trim();
      // }

      return titleElement.text;
   }

   getMetaContent(metaProperty) {
      let el = document.querySelector("meta[name='" + metaProperty + "']");
      if (el) {
         return el.getAttribute('content');
      }

      return null;
   }

   getOgContent(ogProperty) {
      let ogUrlElement = document.querySelector('[property="og:' + ogProperty + '"][content]');
      if (!ogUrlElement) {
         return null;
      }
      return ogUrlElement.attributes['content'].value;
   }

   prepareEvent(originalEvent) {
      if (Array.isArray(originalEvent)) {
         return originalEvent.map(this.slugify);
      } else {
         return this.slugify(originalEvent);
      }
   }

   /**
    * @param {string} originalName
    * @returns {string} slug for originalName string
    */
   slugify(originalName) {
      const result = originalName.toLowerCase().trim();
      return result.replace(/([^a-z0-9])+/gi, '-');
   }

   getDefaultContent(url) {
      //trim '/'
      const urlObj = new URL(url);
      let path = urlObj.pathname.replace(/^\/+|\/+$/g, '');
      if (!path) {
         return 'homepage';
      }

      return path;
   }

   getUrlParams(url) {
      const urlObj = new URL(url.toLowerCase());

      let id = '';
      if (Array.isArray(this.contentIdParams) && this.contentIdParams.length > 0) {
         for (let p of this.contentIdParams) {
            let lowerP = p.toLowerCase();
            let param = urlObj.searchParams.get(lowerP);
            if (param) {
               id += '_' + lowerP + '_' + param;
            }
         }
      }

      return id;
   }

   addKeywordSelector(selector) {
      this.keywordSelectors.push(selector);
   }

   getKeywords(data) {
      let keywords = [];
      this.keywordSelectors.forEach(selector => {
         document.querySelectorAll(selector).forEach(el => {
            if (el.innerText.length > 0) {
               keywords.push(el.innerText);
            }
         });
      });

      if (keywords.length === 0) {
         keywords = data.meta.keywords;
      } else if (Array.isArray(data.meta.keywords)) {
         keywords = [...data.meta.keywords, ...keywords];
      } else if (data.meta.keywords !== undefined) {
         keywords = [data.meta.keywords, ...keywords];
      }

      return keywords;
   }

   //exclude all protocols like (FTP, SMTP, SSH, File, Data and others) except http or https
   isValidHttpUrl(string) {
      let url;
      try {
         url = new URL(string);
      } catch (_) {
         return false;
      }
      return url.protocol === "http:" || url.protocol === "https:" || url.protocol === "";
   }

   getUrl() {
      try {
         let url = this.getOgContent('url');
         if (!url || window.location.href.indexOf(url) === -1) {
            url = window.location.href;
         }

         //validate that it is a properly formed url
         const urlObj = new URL(url);
         return urlObj.href
      } catch (e) {
         return window.location.href
      }
   }

   getContentId(url) {

      if (this.humInfo.contentId) {
         return this.humInfo.contentId
      }

      //detect if this is a scholarly article and prep silverchair specific metadata
      let contentId = this.getMetaContent('citation_doi');
      if (contentId && contentId.length > 6) {
         //if contentId is less than 6 characters this is a bug in the target website
         return contentId
      }

      return this.getDefaultContent(url) + this.getUrlParams(window.location.href);
   }
}

// return cookie value or undefined
export function getCookie(name) {
   const matches = document.cookie.match(new RegExp(
      // eslint-disable-next-line no-useless-escape
      '(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)',
   ));
   return matches ? decodeURIComponent(matches[1]) : undefined;
}

/**
 * 
 * @param {string} name 
 * @param {string} value 
 * @param {{path: string, expires: number, domain: *, SameSite: string, Secure: boolean}} options
 */
export function setCookie(name, value, options) {
   options = options || {};

   var expires = options.expires;

   if (typeof expires == 'number' && expires) {
      var d = new Date();
      d.setTime(d.getTime() + expires * 1000);
      expires = options.expires = d;
   }
   if (expires && expires.toUTCString) {
      options.expires = expires.toUTCString();
   }

   value = encodeURIComponent(value);

   var updatedCookie = name + '=' + value;

   for (var propName in options) {
      updatedCookie += '; ' + propName;
      var propValue = options[propName];
      if (propValue !== true) {
         updatedCookie += '=' + propValue;
      }
   }

   document.cookie = updatedCookie;
}

export function deleteCookie(name) {
   setCookie(name, '', {
      expires: -1,
      path: '/',
   });
}
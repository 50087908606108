const feature = {};

Object.defineProperty(feature, 'Sc10075', { value: '10075-merge-visitor-with-event', writable: false });
Object.defineProperty(feature, 'Sc9199_1', { value: '9199_track_gam_impressions', writable: false });
Object.defineProperty(feature, 'Sc12738', { value: '12738-disable-lead-gen-modals-if-a-page-has-interstitial-ads-gam', writable: false });
Object.defineProperty(feature, 'Sc13158', { value: '13158-use-shareable-link-for-campaign-preview', writable: false });
Object.defineProperty(feature, 'Sc12792', { value: '12792-replacement-for-live-engagement-campaign-for-when-institution-name-replacement-string-is-found', writable: false });
Object.defineProperty(feature, 'Sc13859', { value: '13859-update-campaign-antifatigue-settings-to-account-for-multiple-tabs', writable: false });
Object.defineProperty(feature, 'Sc13767', { value: '13767-sdk-track-recommendation-impression-and-click', writable: false });
Object.defineProperty(feature, 'Sc13022', { value: '13022-add-option-for-lead-gen-link-to-open-in-new-tab', writable: false });
Object.defineProperty(feature, 'Sc13600', { value: '13600-recommendations-include-current-content-embedding', writable: false });
Object.defineProperty(feature, 'Sc13756', { value: '13756-add-preview-link-to-recommendations-widget', writable: false });
Object.defineProperty(feature, 'Sc15218', { value: '15218-live-engagement-campaign-clear-disable-the-delay-field-when-inline-prompt-type-is-selected', writable: false });
Object.defineProperty(feature, 'Sc12999', { value: '12999-add-wysiwyg-editor-for-prompt-body-and-footer', writable: false });
Object.defineProperty(feature, 'Sc15501', { value: '15501-add-metadata-to-capture-the-form-they-identified-on-or-that-they-identified-by-a-secondary-id', writable: false });
Object.defineProperty(feature, 'Sc16384', { value: '16384-live-engagement-campaign-set-prompt-actions-position', writable: false });
Object.defineProperty(feature, 'Sc16175', { value: '16175-cra-bombora-integration', writable: false });
Object.defineProperty(feature, 'Sc16728', { value: '16728-update-campaign-mapping-with-nested-variants', writable: false });
Object.defineProperty(feature, 'Sc17489', { value: '17489-inline-prompt-ignore-display-freq', writable: false });
Object.defineProperty(feature, 'Sc17695', { value: '17695-live-engagement-campaign-preview-link-display-a-warning-message', writable: false });
Object.defineProperty(feature, 'Sc18399', { value: '18399-campaign-preview-link-display-error-message-if-css-selector-is-not-found-on-the-page', writable: false });
Object.defineProperty(feature, 'Sc17569', { value: '17569-campaigns-add-custom-form-fields', writable: false });
Object.defineProperty(feature, 'Sc18854', { value: '18854-add-ability-to-set-custom-attributes-on-hum-iframe', writable: false });
Object.defineProperty(feature, 'Sc18435', { value: '18435-display-ad-campaign-gaining-0-impressions-despite-being-very-broad', writable: false });
Object.defineProperty(feature, 'Sc16137', { value: '16137-use-personalization-endpoint', writable: false });


export default feature;


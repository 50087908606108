export function initBombora(tracker) {
   if (tracker?.settings?.bomboraEnabled) {
      if (window._bmb) {
         window._bmb('vi', (data) => {
            if(data) {
               sendBomboraEvent(tracker, data);
            }
         });
      }
   }
}

function sendBomboraEvent(tracker, data) {
   const bomboraFields = tracker.settings?.bomboraFields ? tracker.settings?.bomboraFields : Object.keys(data);

   const storedBomboraData = JSON.parse(localStorage.getItem(`${tracker.api.clientShortName}_bombora_data`)) || {};
   const changedProperties = {};

   const processField = (field) => {
      if (typeof data[field] === 'number') {
         data[field] = data[field].toString();
      }

      if (Array.isArray(data[field])) {
         let dataAr = JSON.stringify(data[field]?.sort());
         let lsAr = JSON.stringify(storedBomboraData[field]?.sort());

         if (dataAr !== lsAr) {
            changedProperties[field] = data[field];
         }
      } else {
         if (data[field] !== storedBomboraData[field]) {
            changedProperties[field] = data[field];
         }
      }
   };

   for (const field of bomboraFields) {
      processField(field);
   }

   if (Object.keys(changedProperties).length > 0) {
      let eventData = {
         event: "bombora-vi",
         meta: changedProperties,
         source: "bombora",
         url: tracker.api.getUrl()
      }

      tracker.api.sendRawEvent(eventData).then(() => {
         localStorage.setItem(`${tracker.api.clientShortName}_bombora_data`, JSON.stringify(data));
      });
   }
}
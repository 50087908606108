import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';
import {randomBeta} from 'd3-random';
import './styles/common.scss';
import './styles/pop-up-modal.scss';
import './styles/slide-in-box.scss';
import './styles/banner.scss';
import './styles/inline.scss';
import './styles/preview-modals.scss';

import {magnetIsAllowedByPatterns} from './rule';
import feature from './feature';
import countryCodes from './countryCodes';
import statesUS from './statesUS';
import provincesCanada from './provincesCanada';
import {isExpired, setExpirationAfter} from './time';

const loaders = [];
let lock = false;
let magnet = null;

const LAST_SHOWN_MAGNET_TIMESTAMP = 'LAST_SHOWN_MAGNET_TIMESTAMP';
const NUM_MAGNETS_SHOWN = 'NUM_MAGNETS_SHOWN';
const MAGNET_SETTINGS_EXPIRATION = 'MAGNET_SETTINGS_EXPIRATION';

/**
 * @param {Tracker} tracker
 */
async function queueMagnetLoading(tracker) {
   loaders.push({loader: loadMagnet, tracker: tracker});

   if (!lock) {
      while (loaders.length && !magnet) {
         let {loader, tracker} = loaders.pop();
         await loader(tracker);
      }
      lock = false;
   }
}

async function loadMagnet(tracker) {
   lock = true;
   let res = getMagnetCampaign(tracker);
   if (!res) {
      return;
   }

   if (tracker.featureFlags[feature.Sc16728]) {
      res = applyBestVariantToCampaign(res);
   }

   const delay = function (magnetShowFunc) {
      setTimeout(magnetShowFunc, getDelay(magnet));
   };

   if (isMobileDevice() && res?.form_data?.hide_on_mobile) {
      return;
   }
   if (res.form_data && Object.keys(res.form_data).length !== 0) {
      magnet = res;
      if (!tracker.featureFlags[feature.Sc15218] || magnet.form_data.prompt_type !== 'inline') {
         delay(getMagnetShowFuncByTemplateData(tracker, magnet));
      } else {
         getMagnetShowFuncByTemplateData(tracker, magnet)();
      }
   }
}

function getMagnetCampaign(tracker) {

   const {campaigns} = tracker.campaigns;

   let visitorCampaigns = [];
   const globallyExcludedPages = tracker.settings?.leadGenCampaigns?.excludedPages;

   if (Array.isArray(campaigns)) {
      const magnets = campaigns.filter(c => {
         const isMagnet = c.template === 'magnet' && canShowMagnet(c);

         const currentDate = new Date();
         const isActive = currentDate >= new Date(c.active_from) && currentDate <= new Date(c.active_to);

         return isMagnet && isActive;
      })
      for (const magnet of magnets) {
         if (magnetIsAllowedByPatterns(window.location.href, magnet.url_patterns, magnet.excluded_urls, globallyExcludedPages)) {
            visitorCampaigns.push(magnet);
         }
      }
      return chooseBestCampaign(visitorCampaigns, tracker.settings.leadGenCampaigns);
   }
   return null;
}

function chooseBestCampaign(visitorCampaigns, campaignSettings) {
   const leadCampaignScores = campaignSettings?.leadCampaigns || [];

   let best_score = -1;
   let chosen_campaign;
   let campaign_score;
   for (let i = 0; i < visitorCampaigns.length; i++) {
      const campaign = visitorCampaigns[i];
      const impressions = campaign.metrics?.impressions || 0;
      const conversions = campaign.metrics?.conversions || 0;
      let customerValue = 5;

      const matchingCampaign = leadCampaignScores.find(magnet => magnet.code === campaign.code);
      if (matchingCampaign && matchingCampaign.assignedPoints) {
         customerValue = matchingCampaign.assignedPoints;
      }

      let campaign_distribution = randomBeta(conversions + 1, impressions - conversions + 1);
      campaign_score = campaign_distribution() * customerValue;
      if (campaign_score > best_score) {
         chosen_campaign = campaign;
         best_score = campaign_score;
      }
   }

   return chosen_campaign;
}

/**
 * @param tracker
 * @param {Object} magnet  - magnet campaign object
 * @returns {(function(): Promise<void>)|*}
 */
function getMagnetShowFuncByTemplateData(tracker, magnet) {
   if (tracker.featureFlags[feature.Sc12999]) {
      magnet = convertToSnakeCase(magnet)
   }

   let data = {}
   if (tracker.featureFlags[feature.Sc16728]) {
      data = {form_id: magnet.code, variant_id: magnet?.chosen_best_variant_id};
   } else {
      data = {form_id: magnet.code};
   }
   let hideModal = false;
   const accountForMultipleTabsFeature = tracker.featureFlags[feature.Sc13859];

   return async function showMagnet() {
      if (accountForMultipleTabsFeature) {
         if (document.visibilityState !== 'visible') {
            return;
         }
      }

      let popupIsModal = true;
      if (magnet.form_data.prompt_type && magnet.form_data.prompt_type !== 'modal') {
         popupIsModal = false;
      }

      if (!tracker.arePopupsAllowed() && popupIsModal) {
         return;
      }

      let primaryInstitutionName = '';
      if (tracker.featureFlags[feature.Sc12792]) {
         primaryInstitutionName = tracker.campaigns.primary_institution || '';
      }

      if (accountForMultipleTabsFeature) {
         if (tracker.featureFlags[feature.Sc17489]) {
            if (magnet.form_data.prompt_type !== 'inline') {
               if (!userIsValidForMagnetGlobalSettings(tracker)) return;
               let numMagnetsShown = localStorage.getItem(NUM_MAGNETS_SHOWN);
               if (!numMagnetsShown || numMagnetsShown === 'null') {
                  numMagnetsShown = '0';
               }
               numMagnetsShown = parseInt(numMagnetsShown);

               // update the variables for global campaign settings check
               localStorage.setItem(NUM_MAGNETS_SHOWN, JSON.stringify(++numMagnetsShown));
               localStorage.setItem(LAST_SHOWN_MAGNET_TIMESTAMP, JSON.stringify(new Date().getTime()));
            }
         } else {
            if (!userIsValidForMagnetGlobalSettings(tracker)) return;
            let numMagnetsShown = localStorage.getItem(NUM_MAGNETS_SHOWN);
            if (!numMagnetsShown || numMagnetsShown === 'null') {
               numMagnetsShown = '0';
            }
            numMagnetsShown = parseInt(numMagnetsShown);

            // update the variables for global campaign settings check
            localStorage.setItem(NUM_MAGNETS_SHOWN, JSON.stringify(++numMagnetsShown));
            localStorage.setItem(LAST_SHOWN_MAGNET_TIMESTAMP, JSON.stringify(new Date().getTime()));
         }
      }

      if (!canShowMagnet(magnet)) {
         return;
      }

      const magnetWrap = document.createElement('div');
      magnetWrap.classList.add('swal-wrapper');
      magnetWrap.classList.add('hum-' + (magnet.form_data.prompt_type ?? 'modal'));

      let options = await prepareSwalOptions(tracker, data, magnet.form_data, magnetWrap);

      updateTs(magnet);
      pubsub.publish('shown', data);

      const shouldUseCustomFormFields = tracker.featureFlags[feature.Sc17569];
      if (!tracker.featureFlags[feature.Sc12999]) {
         const magnetTitle = findAndReplacePrimaryInstitutionName(magnet.form_data?.title, primaryInstitutionName);
         await Swal.fire({
            title: magnetTitle,
            imageUrl: magnet.form_data.banner,
            imageAlt: magnet.form_data.alt ? magnet.form_data.alt : '',
            html: setUpMagnetBody(magnet, primaryInstitutionName, tracker),
            preConfirm: magnetPreConfirm(data),
            willClose: () => hideModal = checkHideModal(),
            didRender: (doc) => magnetDidRender(doc, magnet.form_data, false, tracker.settings),
            didOpen: () => {
               Swal.getPopup().id = magnet.code;
            },
            showConfirmButton: magnet.lead_gen_form || magnet.form_data.call_to_action_toggle ? true : magnet.form_data.call_to_action_toggle,
            confirmButtonText: magnet.form_data.submit_label || 'Submit',
            showCancelButton: magnet.form_data.cancel_toggle === undefined ? true : magnet.form_data.cancel_toggle,
            cancelButtonText: 'Cancel',
            showCloseButton: true,
            footer: setUpMagnetFooter(magnet, primaryInstitutionName, tracker),
            allowOutsideClick: true,
            ...options,
         }).then((result) => {
            if (result.isConfirmed) {
               if (hideModal) {
                  appendPermanentlyDismissed(data.form_id);
               }

               pubsub.publish('confirmed', data);
               removeTs(magnet);
            } else if (result.isDenied || result.isDismissed) {
               if (hideModal) {
                  pubsub.publish('dismiss', data);
                  return;
               }

               pubsub.publish('cancelled', data);
            }
         });
      } else {
         await Swal.fire({
            html: setUpMagnetBody(magnet, primaryInstitutionName, tracker),
            preConfirm: magnetPreConfirm(data),
            willClose: () => hideModal = checkHideModal(),
            didRender: (doc) => magnetDidRender(doc, magnet.form_data, false, tracker.settings),
            didOpen: () => {
               Swal.getPopup().id = magnet.code;
               if (shouldUseCustomFormFields) {
                  const countrySelectContainers = document.querySelectorAll('.country-select-container');
                  countrySelectContainers.forEach(container => {
                     const matchingStateContainer = findMatchingStateContainer(container);
                     if (matchingStateContainer) {
                        addListenerToCountrySelect(container, matchingStateContainer);
                     }
                  });
               }
            },
            showConfirmButton: magnet.lead_gen_form || magnet.form_data.call_to_action_toggle ? true : magnet.form_data.call_to_action_toggle,
            confirmButtonText: magnet.form_data.submit_label || 'Submit',
            showCancelButton: magnet.form_data.cancel_toggle === undefined ? true : magnet.form_data.cancel_toggle,
            cancelButtonText: 'Cancel',
            showCloseButton: true,
            footer: setUpMagnetFooter(magnet, primaryInstitutionName, tracker),
            allowOutsideClick: true,
            ...options,
         }).then((result) => {
            if (result.isConfirmed) {
               if (hideModal) {
                  appendPermanentlyDismissed(data.form_id);
               }

               pubsub.publish('confirmed', data);
               removeTs(magnet);
            } else if (result.isDenied || result.isDismissed) {
               if (hideModal) {
                  pubsub.publish('dismiss', data);
                  return;
               }

               pubsub.publish('cancelled', data);
            }
         });
      }
   };
}

async function prepareSwalOptions(tracker, data, form_data, wrap) {
   let options;
   switch (form_data.prompt_type) {
      case 'inline':
         options = await prepareInlineWidgetOptions(data, form_data.prompt_css_selector, wrap);
         break;
      case 'slide_in_box':
         options = await prepareSlideInBoxWidgetOptions(data, form_data.prompt_position, wrap);
         break;
      case 'banner':
         options = await prepareBannerWidgetOptions(data, form_data.prompt_position, wrap);
         break;
      default:
         options = await prepareModalWidgetOptions(data, wrap);
   }

   if (tracker.featureFlags[feature.Sc16384]) {
      options = await preparePromptActionPositionOptions(options, form_data.actions_position);
   }

   return options;
}

async function preparePromptActionPositionOptions(options, position) {
   if (position?.length === 0) {
      return options;
   }
   const actions = `swal-actions-${position}`;
   options.customClass = {
      actions,
      ...options.customClass,
   };
   return options;
}

async function prepareModalWidgetOptions(data, wrap) {
   const target = document.body;
   if (target) {
      target.appendChild(wrap);
   }
   return {
      target: wrap,
   };
}

async function prepareInlineWidgetOptions(data, cssSelector, wrap) {
   const target = document.querySelector(cssSelector);
   if (target) {
      target.appendChild(wrap);
   }
   return {
      target: wrap,
      template: '#my-template',
      toast: true,
      width: '100%',
      position: 'bottom',
      showClass: {
         popup: 'animate__fadeIn',
         backdrop: 'swal2-backdrop-hide',
      },
      showCloseButton: false,
      didOpen: () => {
         const popup = document.getElementsByClassName('swal2-toast')[0];
         popup.classList.remove('swal2-toast');
         Swal.getPopup().id = data.form_id;
      },
   };
}

async function prepareSlideInBoxWidgetOptions(data, widgetPosition, wrap) {
   const popupShowClass = widgetPosition.includes('start') ? 'animate__slideInLeft' : 'animate__slideInRight';
   const popupHideClass = widgetPosition.includes('start') ? 'animate__slideOutLeft' : 'animate__slideOutRight';

   const target = document.body;
   if (target) {
      target.appendChild(wrap);
   }
   return {
      target: wrap,
      template: '#my-template',
      toast: true,
      position: widgetPosition,
      showClass: {
         popup: `animate__animated ${popupShowClass}`,
      },
      hideClass: {
         popup: `animate__animated ${popupHideClass}`,
      },
      didOpen: () => {
         const popup = document.getElementsByClassName('swal2-toast')[0];
         popup.classList.remove('swal2-toast');
         Swal.getPopup().id = data.form_id;
      },
   };
}

async function prepareBannerWidgetOptions(data, widgetPosition, wrap) {
   const popupShowClass = widgetPosition.includes('top') ? 'animate__slideInDown' : 'animate__slideInUp';
   const popupHideClass = widgetPosition.includes('top') ? 'animate__slideOutUp' : 'animate__slideOutDown';

   const target = document.body;
   if (target) {
      target.appendChild(wrap);
   }
   return {
      target: wrap,
      template: '#my-template',
      position: widgetPosition,
      grow: 'row',
      backdrop: false,
      showClass: {
         popup: `animate__animated ${popupShowClass}`,
      },
      hideClass: {
         popup: `animate__animated ${popupHideClass}`,
      },
   };
}

const pubsub = new class {
   constructor() {
      this.events = {};
   }

   subscribe(event, handler) {
      if (this.events[event]) {
         this.events[event].push(handler);
      } else {
         this.events[event] = [handler];
      }
   }

   publish(event, ...args) {
      const handlers = this.events[event];
      if (Array.isArray(handlers)) {
         handlers.forEach((handler) => {
            handler(...args);
         });
      }
   }
};

export default class Magnet {
   /**
    * @param {Tracker} tracker
    */
   constructor(tracker) {
      if (!tracker) {
         return console.error('Tracker not provided.');
      }

      this.tracker = tracker;
      this.run();
   }

   async run() {
      if (!(this.tracker.featureFlags[feature.Sc17489])) {
         if (!userIsValidForMagnetGlobalSettings(this.tracker)) return;
      }
      queueMagnetLoading(this.tracker);

      pubsub.subscribe('shown', data => {
         this.tracker.api.sendEvent('lead-magnet-shown', data);
      });
      pubsub.subscribe('confirmed', async data => {
         appendConfirmedForm(data.form_id);
         let primaryEmail = data.email;
         if (this.tracker.featureFlags[feature.Sc17569]) {
            primaryEmail = findPrimaryEmail(data);
         }

         if (primaryEmail) {
            if (this.tracker.featureFlags[feature.Sc15501]) {
               await this.tracker.identify(primaryEmail, {
                  identified_by: 'campaign',
                  identifying_campaign: data.form_id,
                  identifying_campaign_variant: data.variant_id,
               });
            } else {
               await this.tracker.identify(primaryEmail);
            }
         }
         await this.tracker.api.sendEvent('lead-magnet-confirmed', data);
         if (magnet.goal_url) {
            if (this.tracker.featureFlags[feature.Sc13022] && magnet.form_data.open_button_link_in_new_tab) {
               window.open(magnet.goal_url, '_blank');
            } else {
               window.location.href = magnet.goal_url;
            }
         } else {
            Swal.fire('Thank You!', '', 'success');
         }
      });
      pubsub.subscribe('cancelled', data => {
         this.tracker.api.sendEvent('lead-magnet-cancelled', data);
      });
      pubsub.subscribe('dismiss', data => {
         appendPermanentlyDismissed(data.form_id);
         this.tracker.api.sendEvent('lead-magnet-permanently-dismissed', data);
      });
   }
}

// Check if we can show magnet campaigns based on global settings
// Example: Display a max of 2 campaigns within 1 hour
function userIsValidForMagnetGlobalSettings(tracker) {
   let maxCampaignsLimit = tracker.settings?.leadGenCampaigns?.maxCampaigns;
   if (!maxCampaignsLimit) {
      maxCampaignsLimit = 2;
   }
   let timeframeLimit = tracker.settings?.leadGenCampaigns?.timeframe;
   if (!timeframeLimit) {
      timeframeLimit = 24;
   }
   let timeframeType = tracker.settings?.leadGenCampaigns?.timeframeType;
   if (!timeframeType) {
      timeframeType = 'hours';
   }

   // reset all variables if the settings were expired
   if (isExpired(MAGNET_SETTINGS_EXPIRATION)) {
      setExpirationAfter(MAGNET_SETTINGS_EXPIRATION, timeframeLimit * getTimeFactor(timeframeType) / 1000);
      localStorage.setItem(LAST_SHOWN_MAGNET_TIMESTAMP, '0');
      localStorage.setItem(NUM_MAGNETS_SHOWN, '0');
   }

   let lastShownTimestamp = localStorage.getItem(LAST_SHOWN_MAGNET_TIMESTAMP);
   if (!lastShownTimestamp || lastShownTimestamp === 'null') {
      lastShownTimestamp = '0';
   }
   lastShownTimestamp = parseInt(lastShownTimestamp);

   let numMagnetsShown = localStorage.getItem(NUM_MAGNETS_SHOWN);
   if (!numMagnetsShown || numMagnetsShown === 'null') {
      numMagnetsShown = '0';
   }
   numMagnetsShown = parseInt(numMagnetsShown);

   const currentTime = new Date().getTime();
   const timeDifference = currentTime - lastShownTimestamp;

   // first check number of already shown magnets
   if (numMagnetsShown < maxCampaignsLimit) {
      return true;
   }

   // if first check did not pass then check the time limitation
   return timeDifference >= timeframeLimit * getTimeFactor(timeframeType);
}

function getTimeFactor(timeframeType) {
   switch (timeframeType) {
      case 'second':
      case 'seconds':
         return 1000;
      case 'minute':
      case 'minutes':
         return 60 * 1000;
      case 'hour':
      case 'hours':
         return 60 * 60 * 1000;
      case 'day':
      case 'days':
         return 24 * 60 * 60 * 1000;
      default:
         return 1000;
   }
}

const formsConfirmedKey = 'magnet-forms-confirmed';

function canShowMagnet(magnet) {
   return !isFormAlreadyConfirmed(magnet.code) && !isFormPermanentlyDismissed(magnet.code) && !isFormInsideDisplayFrequencyWindow(magnet);
}

function isFormAlreadyConfirmed(formId) {
   const mf = localStorage.getItem(formsConfirmedKey);
   if (!mf) {
      return false;
   }
   const mfArr = JSON.parse(mf);
   return !!mfArr.includes(formId);
}

function isFormInsideDisplayFrequencyWindow(campaign, tracker) {
   let key = magnetTsKey(campaign);
   let data = localStorage.getItem(key);

   if (!data) {
      return false;
   }

   let campaignHistory = JSON.parse(data);

   if (campaignHistory.type === 'start') {
      return false;
   }

   const repeatFactor = getTimeFactor(campaign?.display_frequency?.type);
   const repeatDelay = repeatFactor * (campaign?.display_frequency?.value);

   return Date.now() < campaignHistory.ts + repeatDelay;
}

function isFormPermanentlyDismissed(formId) {
   const mf = localStorage.getItem(formsPermanentlyDismissedKey);
   if (!mf) {
      return false;
   }
   const mfArr = JSON.parse(mf);
   return !!mfArr.includes(formId);
}

function appendConfirmedForm(formId) {
   const mf = localStorage.getItem(formsConfirmedKey);
   if (!mf) {
      localStorage.setItem(formsConfirmedKey, JSON.stringify([formId]));
      return;
   }
   const mfArr = JSON.parse(mf);
   if (!mfArr.includes(formId)) {
      localStorage.setItem(formsConfirmedKey, JSON.stringify([formId, ...mfArr]));
   }
}

const formsPermanentlyDismissedKey = 'magnet-forms-permanently-dismissed';

function appendPermanentlyDismissed(formId) {
   const mf = localStorage.getItem(formsPermanentlyDismissedKey);
   if (!mf) {
      localStorage.setItem(formsPermanentlyDismissedKey, JSON.stringify([formId]));
      return;
   }
   const mfArr = JSON.parse(mf);
   if (!mfArr.includes(formId)) {
      localStorage.setItem(formsPermanentlyDismissedKey, JSON.stringify([formId, ...mfArr]));
   }
}


export function deletePermanentlyDismissedForms() {
   localStorage.removeItem(formsPermanentlyDismissedKey);
}

function magnetTsKey(magnet) {
   return 'ts-' + magnet.code;
}

const typeStartDelay = 'start';
const typeRepeatDelay = 'repeat';

/**
 * Returns number of milliseconds to delay magnet showing for.
 *
 * @param {Object} magnet
 * @return {Number} number of milliseconds
 */
function getDelay(magnet) {
   const key = magnetTsKey(magnet);
   const defaultDelay = magnet?.form_data?.delay || 5;

   const startFactor = getTimeFactor(magnet?.display_delay?.type);
   const startDelay = startFactor * (magnet?.display_delay?.value || defaultDelay);

   const repeatFactor = getTimeFactor(magnet?.display_frequency?.type);
   const repeatDelay = repeatFactor * (magnet?.display_frequency?.value || defaultDelay);

   let delaySettings = {type: typeStartDelay, ts: Date.now()};
   const data = localStorage.getItem(key);
   if (!data) {
      localStorage.setItem(key, JSON.stringify(delaySettings));
   } else {
      delaySettings = JSON.parse(data);
   }

   const delay = (delaySettings.type === typeStartDelay ? startDelay : repeatDelay);

   return Math.max(delaySettings.ts + delay - Date.now(), defaultDelay * 1000);
}

function updateTs(magnet) {
   localStorage.setItem(magnetTsKey(magnet), JSON.stringify({
      ts: Date.now(),
      type: typeRepeatDelay,
   }));
}

function removeTs(magnet) {
   localStorage.removeItem(magnetTsKey(magnet));
}

// We create a similar to real Campaign modal with different checks but not send any data to event endpoint if click on (submit/cancel/dismiss) btn.
export async function showPreviewMagnetCampaign(campaign, tracker) {
   if (campaign.form_data && Object.keys(campaign.form_data).length !== 0) {
      magnet = campaign;
      let previewInstitutionName = '';
      if (tracker.featureFlags[feature.Sc12792]) {
         previewInstitutionName = 'Hum';
      }
      const title = findAndReplacePrimaryInstitutionName(campaign?.form_data?.title, previewInstitutionName);
      const body = setUpMagnetBody(campaign, previewInstitutionName, tracker);
      const footer = setUpMagnetFooter(campaign, previewInstitutionName, tracker);
      let data = {form_id: magnet.code};
      let hideModal = false;

      const submit_label = campaign.form_data.submit_label || 'Submit';

      const shouldUseCustomFormFields = tracker.featureFlags[feature.Sc17569];
      let options;
      if (!tracker.featureFlags[feature.Sc12999]) {
         options = {
            title: title,
            imageUrl: campaign.form_data.banner,
            imageAlt: campaign.form_data.alt ? campaign.form_data.alt : '',
            html: body,
            preConfirm: magnetPreConfirm(data),
            willClose: () => hideModal = checkHideModal(),
            didRender: (doc) => magnetDidRender(doc, data, true, tracker.settings),
            didOpen: () => {
               Swal.getPopup().id = campaign.code;
            },
            showConfirmButton: campaign.lead_gen_form || campaign.form_data.call_to_action_toggle ? true : campaign.form_data.call_to_action_toggle,
            confirmButtonText: submit_label,
            showCancelButton: campaign.form_data.cancel_toggle === undefined ? true : campaign.form_data.cancel_toggle,
            cancelButtonText: 'Cancel',
            showCloseButton: true,
            footer: footer,
            allowOutsideClick: false,
            inputAutoFocus: false,
            allowEnterKey: false,
         };
      } else {
         options = {
            html: body,
            preConfirm: magnetPreConfirm(data),
            willClose: () => hideModal = checkHideModal(),
            didRender: (doc) => magnetDidRender(doc, data, true, tracker.settings),
            didOpen: () => {
               Swal.getPopup().id = campaign.code;
               if (shouldUseCustomFormFields) {
                  const countrySelectContainers = document.querySelectorAll('.country-select-container');
                  countrySelectContainers.forEach(container => {
                     const matchingStateContainer = findMatchingStateContainer(container);
                     if (matchingStateContainer) {
                        addListenerToCountrySelect(container, matchingStateContainer);
                     }
                  });
               }
            },
            showConfirmButton: campaign.lead_gen_form || campaign.form_data.call_to_action_toggle ? true : campaign.form_data.call_to_action_toggle,
            confirmButtonText: submit_label,
            showCancelButton: campaign.form_data.cancel_toggle === undefined ? true : campaign.form_data.cancel_toggle,
            cancelButtonText: 'Cancel',
            showCloseButton: true,
            footer: footer,
            allowOutsideClick: false,
            inputAutoFocus: false,
            allowEnterKey: false,
         };
      }

      const magnetWrap = document.createElement('div');
      magnetWrap.classList.add('swal-wrapper');
      magnetWrap.classList.add('hum-' + (magnet.form_data.prompt_type ?? 'modal'));

      const preparedOptions = await prepareSwalOptions(tracker.settings, data, campaign.form_data, magnetWrap);
      options = {...options, ...preparedOptions};

      Swal.fire(options);
   }
}

function setUpMagnetBody(campaign, primaryInstitutionName, tracker) {
   let body = '';

   if (tracker.featureFlags[feature.Sc12999]) {
      if (campaign.form_data.body_html) {
         const body_html = findAndReplacePrimaryInstitutionName(campaign.form_data.body_html, primaryInstitutionName);
         body += `${body_html}`;
      }
   } else {
      if (campaign.form_data.description) {
         const description = findAndReplacePrimaryInstitutionName(campaign.form_data.description, primaryInstitutionName);
         body += `<p class="swal-description">${description}</p>`;
      }
   }

   if (campaign.form_data.inputs) {
      campaign.form_data.inputs = campaign.form_data.inputs.sort((a, b) => {
         if (Object.hasOwn(a, 'order') && Object.hasOwn(b, 'order')) {
            return a.order - b.order;
         } else {
            return 0;
         }
      });
      body += '<div class="swal-input-container"><form>';

      if (tracker.featureFlags[feature.Sc17569]) {
         for (const [key, value] of Object.entries(campaign.form_data.inputs)) {
            if (!value.checked) continue;

            body += '<div class="swal-input-row">';

            if (value.type) {
               body = addCustomField(body, key, value);
            } else {
               body = addDefaultField(body, key, value);
            }

            if (value.required && value.type !== 'checkbox') {
               // Hide the required span for states/provinces until needed.
               const hideClass = (value.type === 'location' && value.slug.includes('state')) ? 'swal-hidden' : '';
               body += `<span class="swal-span-required ${hideClass}">Required *</span>`;
            }
            body += '</div>';

         }
      } else {
         for (const [key, value] of Object.entries(campaign.form_data.inputs)) {
            if (!value.checked) continue;

            body += '<div class="swal-input-row">';

            body = addDefaultField(body, key, value);

            if (value.required) {
               body += '<span class="swal-span-required">Required *</span>';
            }
            body += '</div>';

         }
      }

      body += '</form></div>';
   }

   return body;
}

function setUpMagnetFooter(campaign, primaryInstitutionName, tracker) {
   let footer = '<div>';

   if (tracker.featureFlags[feature.Sc12999]) {
      if (campaign.form_data.footer_html) {
         const footer_html = findAndReplacePrimaryInstitutionName(campaign.form_data.footer_html, primaryInstitutionName);
         footer += `${footer_html}`;
      }
   } else {
      if (campaign.form_data.privacy_link) {
         const privacyTxt = findAndReplacePrimaryInstitutionName(campaign.form_data.privacy_text, primaryInstitutionName) || 'Privacy Policy';
         const targetAttr = tracker.featureFlags[feature.Sc13022] && campaign.form_data.open_privacy_policy_in_new_tab ? 'target="_blank"' : '';
         footer += `<a class="privacy-policy" href="${campaign.form_data.privacy_link}" 
      ${targetAttr}>${privacyTxt}</a>`;
      }
   }

   if (campaign.form_data.hide_modal_display) {
      const hide_modal_text = campaign.form_data.hide_modal_text || "Don't show me this message again.";
      footer += `<div class="hide-modal-container">
            <input id="hide-modal-input" style="width: auto !important;" type="checkbox">&nbsp;&nbsp;
            <label for="hide-modal-input">${hide_modal_text}</label>
         </div>`;
   }
   footer += `</div>`;

   return footer;
}

function magnetPreConfirm(data) {
   return () => {
      let allRequiredFills = true;
      const elements = Array.from(document.querySelectorAll('[data-key]'));
      elements.forEach(el => {
         const docEl = document.getElementById(el.id);
         if (docEl) {
            docEl.style.border = '1px solid #d9d9d9';
            document.getElementById(`error-` + el.dataset.label)?.remove();
            if ((el.dataset.required === 'true' && docEl.value === '') ||
               (el.dataset.key === 'email' && !validateEmail(docEl.value)) ||
               (el.dataset.key === 'country' && docEl.value === 'Country')
            ) {
               allRequiredFills = false;
               docEl.style.border = '1px solid red';
            }
         }
      });
      if (!allRequiredFills) {
         return false;
      }
      elements.forEach(el => {
         const docEl = document.getElementById(el.id);
         if (docEl && docEl.value) {
            switch (el.type) {
               case 'checkbox':
               case 'radio':
                  if (el.checked) {
                     data[el.dataset.key] = docEl.value;
                  }
                  break;
               default:
                  data[el.dataset.key] = docEl.value;
            }
         }
      });
   };
}

function checkHideModal() {
   return document.getElementById('hide-modal-input')?.checked ?? false;
}

function magnetDidRender(magnetDoc, data, previewMode = false, settings) {
   // .swal2-title should not be removed, only hidden
   const classesToSearch = [
      '.swal2-input', '.swal2-file', '.swal2-range', '.swal2-select', '.swal2-radio', '.swal2-checkbox',
      '.swal2-textarea', '.swal2-image', '.swal2-icon', '.swal2-progress-steps', '.swal2-cancel', '.swal2-deny',
      '.swal2-validation-message', '.swal2-timer-progress-bar', '.swal2-timer-progress-bar-container'
   ];
   const selectorString = classesToSearch.map(classSelector => `${classSelector}[style*="display: none"]`).join(',');

   const hiddenElements = magnetDoc.querySelectorAll(selectorString);
   hiddenElements.forEach(el => el?.remove());

   if (data.prompt_type === 'inline') {
      magnetDoc.removeAttribute('tabindex');
   }

   if (previewMode) {
      addPreviewTooltipToButtons(magnetDoc)
   }

   if (settings?.enableCodeSnippets === true) {
      cutAndRunScript(magnetDoc);
   }
}

function cutAndRunScript(magnetDoc) {
   const htmlContainer = magnetDoc.querySelector('.swal2-html-container');
   const footer = magnetDoc.querySelector('.swal2-footer');

   if (htmlContainer) {
      htmlContainer.innerHTML = htmlContainer.innerHTML.replaceAll("&lt;", "<").replaceAll("&gt;", ">");
   }

   if (footer) {
      footer.innerHTML = footer.innerHTML.replaceAll("&lt;", "<").replaceAll("&gt;", ">");
   }

   const scripts = [];
   if (htmlContainer) {
      const htmlScripts = Array.from(htmlContainer.getElementsByTagName('script'));
      scripts.push(...htmlScripts);
   }
   if (footer) {
      const footerScripts = Array.from(footer.getElementsByTagName('script'));
      scripts.push(...footerScripts);
   }

   const loadAndExecuteScripts = (scripts) => {
      const promises = scripts.map(script => {
         return new Promise((resolve, reject) => {
            const scriptElement = document.createElement('script');
            if (script.src) {
               scriptElement.src = script.src;
               scriptElement.onload = resolve;
               scriptElement.onerror = reject;
            } else {
               resolve();
            }
            document.head.appendChild(scriptElement);
         });
      });
      return Promise.all(promises);
   };

   const executeScripts = (scripts) => {
      scripts.forEach(script => {
         if (!script.src) {
            try {
               eval(script.innerText);
            } catch (error) {
               console.error('Error executing script:', error);
            }
         }
      });
   };

   // Load scripts asynchronously and wait for all to be loaded
   loadAndExecuteScripts(scripts)
      .then(() => {
         // All scripts loaded, now execute their content
         executeScripts(scripts);
      })
      .catch(error => {
         console.error('Error loading or executing scripts:', error);
      });
}

function addPreviewTooltipToButtons(doc) {
   let swalButtons = doc.querySelectorAll('button');

   swalButtons.forEach((swalButton) => {
      let tooltipDiv = document.createElement('div');
      tooltipDiv.className = 'hum-tooltip hum-tooltip-top';
      tooltipDiv.innerHTML = "You're in preview mode, which is for visual demonstration only. Prompt buttons are not clickable.";

      let wrapDiv = document.createElement('div');
      wrapDiv.className = 'hum-tooltip-container';

      let clonedButton = swalButton.cloneNode(true);
      clonedButton.classList.add('hum-disabled');

      wrapDiv.appendChild(clonedButton);
      wrapDiv.appendChild(tooltipDiv);

      if (swalButton.classList.contains('swal2-close')) {
         let subWrap = document.createElement('div');
         subWrap.style.display = "flex";
         subWrap.style.justifyContent = "end";
         subWrap.appendChild(wrapDiv);

         swalButton.parentNode.replaceChild(subWrap, swalButton);
      } else {
         swalButton.parentNode.replaceChild(wrapDiv, swalButton);
      }
   });
}


function isMobileDevice() {
   return /Mobile/i.test(navigator.userAgent) || window.innerWidth <= 768;
}

function validateEmail(email) {
   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
   return emailRegex.test(email);
}

function findAndReplacePrimaryInstitutionName(text = '', primaryInstitutionName) {
   return text.replaceAll('%%primary_institution_name%%', primaryInstitutionName);
}

function convertToSnakeCase(obj) {
   if (typeof obj !== 'object' || obj === null) {
      return obj;
   }

   if (Array.isArray(obj)) {
      return obj.map(convertToSnakeCase);
   }

   return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [
         key.replace(/[A-Z]/g, match => `_${match.toLowerCase()}`),
         convertToSnakeCase(value),
      ]),
   );
}

function applyVariantToCampaign(campaign, selectedVariant) {
   campaign.form_data = selectedVariant.form_data;
   campaign.goal_url = selectedVariant.goal_url;
   campaign.lead_gen_form = selectedVariant.lead_gen_form;
   campaign.active_from = selectedVariant.active_from;
   campaign.active_to = selectedVariant.active_to;
   campaign.chosen_best_variant_id = selectedVariant.variant_id;

   return campaign;
}

function applyBestVariantToCampaign(campaign) {
   if (campaign?.variants) {
      let best_score = -1;
      let chosen_variant;
      let variant_score;

      for (let i = 0; i < campaign.variants.length; i++) {

         const variant = campaign.variants[i];
         if (isVariantStarted(variant)) {
            if (campaign.variants.length === 1) {
               return applyVariantToCampaign(campaign, variant);
            }

            const impressions = variant?.impressions || 0;
            const conversions = variant?.conversions || 0;

            let variant_distribution = randomBeta(conversions + 1, impressions - conversions + 1);
            variant_score = variant_distribution();
            if (variant_score > best_score) {
               chosen_variant = variant;
               best_score = variant_score;
            }
         }
      }

      if (chosen_variant) {
         return applyVariantToCampaign(campaign, chosen_variant);
      }
   }

   return campaign;
}

function isVariantStarted(variant) {
   const currentDate = new Date();
   const fromDate = new Date(variant.active_from);
   const toDate = new Date(variant.active_to);

   if (currentDate <= fromDate && currentDate <= toDate) {
      return true;
   }

   return currentDate >= fromDate && currentDate <= toDate;
}

export async function showPreviewMagnetWarning() {
   const title = '<p class="swal2-warning-title">&#128075; Heads Up!';
   const body = `<p class="swal2-warning-desc">This page isn't included in the current active page rules. The prompt will not appear here when your campaign goes live.</p>`;
   const submit_label = 'Preview Anyway';

   const options = {
      icon: "warning",
      title: title,
      html: body,
      showConfirmButton: true,
      confirmButtonText: submit_label,
      showCancelButton: false,
      showCloseButton: true,
      allowOutsideClick: false,
   };

   let showAnyway;
   await Swal.fire(options).then((result) => {
      showAnyway = result.isConfirmed;
   });

   return showAnyway;
}

export async function verifyInlineCssSelector(css_selector) {
   const target = document.querySelector(css_selector);
   if (target) {
      return Promise.resolve();
   }

   const title = '<p class="swal2-warning-title">We couldn’t find your CSS Selector';
   const body = `<p class="swal2-warning-desc">The CSS selector you entered couldn’t be found on this page, so we’re unable to display the prompt. Please check the selector and try again.</p>`;
   const submit_label = 'Close Preview';

   const options = {
      icon: "error",
      title: title,
      html: body,
      showConfirmButton: true,
      confirmButtonText: submit_label,
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      showCloseButton: true,
      allowOutsideClick: false,
   };

   return await Swal.fire(options);
}

function findMatchingStateContainer(countryContainer) {
   const key = countryContainer.getAttribute('data-key');

   let stateKey = 'state';
   if (key !== 'country') {
      const prefix = key.replace('_country', '');
      stateKey = `${prefix}_state`;
   }

   return document.querySelector(`.state-select-container[data-key='${stateKey}']`);
}

function addListenerToCountrySelect(countryContainer, stateContainer) {
   countryContainer.addEventListener('change', function (event) {
      const selectedCountry = event.target.value;
      const stateSelectContainer = stateContainer;
      let placeholder = stateSelectContainer.getAttribute('data-placeholder');
      const isRequired = stateSelectContainer.getAttribute('data-isrequired');
      const inputRow = stateSelectContainer.parentElement.closest('.swal-input-row');

      if (selectedCountry === 'US' || selectedCountry === 'CA') {
         const selectedCountryRegions = selectedCountry === 'US' ? statesUS : provincesCanada;
         if (!placeholder) {
            placeholder = selectedCountry === 'US' ? 'U.S. States/Territories' : 'Canadian Provinces';
         }
         let stateSelectHTML = `<option selected disabled value="">${placeholder ?? ''}</option>`;
         selectedCountryRegions.map(({name, code}) => stateSelectHTML += `<option value="${code}">${name}</option>`);

         //make states/provinces visible and update required status
         stateSelectContainer.setAttribute('data-required', isRequired);
         stateSelectContainer.innerHTML = stateSelectHTML;
         Array.from(inputRow.children).forEach(child => {
            child.classList.remove('swal-hidden');
         });
      } else {
         //reset states/provinces visibility, values and requirements
         Array.from(inputRow.children).forEach(child => {
            child.classList.add('swal-hidden');
         });
         stateSelectContainer.value = '';
         stateSelectContainer.setAttribute('data-required', 'false');
      }
   });
}

function addCustomField(body, key, value) {
   const {label, required, slug, placeholder, type, select_options, is_primary} = value;
   let isCountry;

   switch (type) {
      case 'text':
      case 'email':
         body += `<label for="swal-input${key}" class="swal-input-label">${label}<input data-label="${label}" data-required="${required}" data-key="${slug}" id="swal-input${key}" class="swal2-input swal-input" type="${type}" placeholder="${placeholder ?? ''}"></label>`;
         if (is_primary && type === 'email') {
            body += `<input data-key="primary_email" id="swal-inputis_primary" type="text" value="${slug}" style="display: none !important; visibility: hidden !important">`;
         }
         break;
      case 'checkbox':
         body += `<label for="swal-input${key}" class="swal-input-label swal-checkbox-label"><input data-label="${label.replace(/(<([^>]+)>)/gi, "")}" data-required="${required}" data-key="${slug}" id="swal-input${key}" class="swal2-input swal-input" type="${type}">${label}</label>`;
         break;
      case 'select':
         body += `<label for="swal-select${key}" class="swal-input-label">${label}<select id="swal-select${key}" class="swal2-input swal-input swal2-select swal-select" data-required="${required}" data-key="${slug}">`;
         body += `<option selected disabled value="">${placeholder ?? ''}</option>`;
         select_options.map(({label, value}) => body += `<option value="${value}">${label ? label : value}</option>`);
         body += `</select></label>`;
         break;
      case 'location':
         isCountry = slug.includes('country');

         if (isCountry) {
            body += `<label for="swal-select${key}" class="swal-input-label">${label}<select id="swal-select${key}" class="swal2-input swal-input swal2-select swal-select country-select-container" data-required="${required}" data-key="${slug}">`;
            body += `<option selected disabled>${placeholder ?? ''}</option>`;
            countryCodes.map(({name, iso}) => body += `<option value="${iso}">${name}</option>`);
            body += `</select></label>`;
            break;
         }

         // States/provinces will be hidden and empty until needed. Construct parent element here since this is the only place where we fill the attributes.
         body += `<label for="swal-select${key}" class="swal-input-label swal-hidden">${label}<select id="swal-select${key}" class="swal2-input swal-input swal2-select swal-select state-select-container" data-key="${slug}" data-label="${label}" data-isrequired="${required}" data-placeholder="${placeholder ?? ''}"></select></label>`;
         break;
   }

   return body;
}

function addDefaultField(body, key, value) {
   const {label, required, slug} = value;

   switch (slug) {
      case 'country':
         body += `<label for="swal-select${key}" class="swal-input-label">${label}<select id="swal-select${key}" class="swal2-input swal-input swal2-select swal-select" data-required="${required}" data-key="${slug}">`;
         body += `<option selected disabled>${label}</option>`;
         countryCodes.map(({name, iso}) => body += `<option value="${iso}">${name}</option>`);
         body += `</select></label>`;
         break;
      default:
         body += `<label for="swal-input${key}" class="swal-input-label">${label}<input data-label="${label}" data-required="${required}" data-key="${slug}" id="swal-input${key}" class="swal2-input swal-input" type="${slug === 'email' ? 'email' : 'text'}" placeholder="${label}"></label>`;
   }

   return body;
}

function findPrimaryEmail(data) {
   if (Object.prototype.hasOwnProperty.call(data, 'primary_email')) {
      const primaryEmailKey = data.primary_email;
      delete data['primary_email'];

      if (Object.prototype.hasOwnProperty.call(data, primaryEmailKey)) {
         return data[primaryEmailKey];
      }
   }

   for (const key in data) {
      if (key.endsWith('email')) {
         return data[key];
      }
   }

   return null;
}
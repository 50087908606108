import Tracker from './tracker';

//legacy support. Get tracker settings within hum-blog
window.humTracker = window.humTracker || {};
let tracker = window.humTracker;

// Get tracker settings within GTM
if (window.hum) {
   for (const trackerKey in window.hum) {
      if ( trackerKey.endsWith('Tracker') && window.hum[trackerKey] && !window.hum[trackerKey].initialized ) {
         window.hum[trackerKey].initialized = true;
         tracker = window.hum[trackerKey];
         break;
      }
   }
}

// Get list of tracker settings within a wordpress blog
window.humTrackers = window.humTrackers || [];

if (tracker?.options) {
    new Tracker(tracker);
} else if((window.humTrackers || []).length) {
   window.humTrackers.forEach(function(humTracker) {
      new Tracker(humTracker);
   });
} else {
   console.error('Hum trackers not defined');
}

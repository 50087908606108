export const matchPattern = (value, patterns) => {
    if (!patterns || patterns.length === 0) {
        return true;
    }

    value = value.toLowerCase();
    for (const p of patterns) {
        const { type, pattern: rawPattern } = p;
        const pattern = rawPattern.toLowerCase();
        let res;
        switch (type) {
            case 'exact':
                res = value === pattern;
                break;
            case 'prefix':
                res = value.startsWith(pattern);
                break;
            case 'suffix':
                res = value.endsWith(pattern);
                break;
            case 'contains':
                res = value.includes(pattern);
                break;
            case 'regexp':
                try {
                    // invalid value pattern error possible
                    const regex =  new RegExp(pattern);
                    res = regex.test(value);
                    break;
                } catch (e) {
                    console.warn(e.message);
                    continue;
                }
            case 'css':
                try {
                    res = document.querySelector(rawPattern);
                    break;
                } catch (e) {
                    console.warn(e.message);
                    continue;
                }
        }

        if (res) {
            return true;
        }
    }

    return false;
};

export const magnetIsAllowedByPatterns = (url, magnetPagesPatterns, magnetExcludedPages, globallyExcludedPages) => {
   if (!matchPattern(url, magnetPagesPatterns))
      return false;

   if (magnetExcludedPages && magnetExcludedPages.length > 0 && matchPattern(url, magnetExcludedPages))
      return false;

   return !(globallyExcludedPages && globallyExcludedPages.length > 0 && matchPattern(url, globallyExcludedPages));
}

import {getCookie, setCookie} from './cookie';
import * as iframe from './iframe_storage';
import {applySettings} from './settings';
import {isExpired, setExpirationAfter} from './time';
import Api from './api';
import {debounce} from 'throttle-debounce';
import Magnet, {showPreviewMagnetCampaign, showPreviewMagnetWarning, verifyInlineCssSelector} from './magnet';
import GoogleAdManager from './googleAdManager';
import feature from "./feature";
import Swal from "sweetalert2";
import {initBombora} from "./bombora";
import PageHistory, {trackPageHistory} from "./pageHistory";
import {magnetIsAllowedByPatterns} from './rule';

/*global VISITOR_WAIT_TIMEOUT*/

// eslint-disable-next-line no-control-regex
const emailRe = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])/;
const MAX_LENGTH = 5000;
const COMPLETED = 'COMPLETED';
const WAITING = 'WAITING';

export default class Tracker {
   constructor(tracker) {
      this.formSubmitListeners = {};
      this.visitorId = '';
      this.settings = null;
      this.featureFlags = {};
      this.personalizationData = null;
      this.popupsAreAllowed = true;
      this.trackerOptions = tracker;
      this.init(tracker);
      this.eventsToSendWithPageView = [];
      this.contentSource = "";
      this.campaigns= {"campaigns":[], "primaryInstitutionName":""};
   }

   /**
    * @typedef {{
    *    apiKey: string,
    *    cookieDomain?: string,
    * }} HumOptions
    */

   /** @typedef {{method: string, args: any[]}} PreloadQueueItem */

   /**
    * @typedef {{
    *    options: HumOptions,
    *    onLoad?: function,
    *    preloadQueue?: PreloadQueueItem[],
    * }} tracker
    */

   /**
    * @typedef {{
    *    options: HumOptions,
    *    onLoad?: function,
    *    preloadQueue?: PreloadQueueItem[],
    * }} tracker
    */

   init = async (tracker) => {
      if (!tracker?.options) {
         return console.error('Incorrect tracker initialization');
      }

      const {onLoad, preloadQueue = []} = tracker;

      const {
         userId,
         contentSource,
         apiKey,
         apiUrl,
         cookieName,
         clientShortName = new URL(apiUrl).hostname.split('.')[0],
      } = tracker.options;

      tracker.contentSource = contentSource;
      this.clientShortName = clientShortName;

      const visitorCookieName = cookieName || `hum_${clientShortName}_visitor`;
      const visitorMatchedCookieName = `${visitorCookieName}_matched`;
      this.visitorReqDelay = tracker.options.visitorReqDelay || VISITOR_WAIT_TIMEOUT || 0;

      let cookieDomain = tracker.options.cookieDomain;
      if (!location.origin.endsWith(cookieDomain)) {
         // use hostname, because it does not include port, which is not valid for cookie name
         cookieDomain = location.hostname;
      }

      this.cookieDomain = cookieDomain;

      if (typeof apiKey !== 'string' || apiKey.length === 0) {
         return console.error('Api Key required');
      }


      this.api = new Api();
      this.pageHistory = new PageHistory(clientShortName, this.api);
      this.api.setApiKey(apiKey);

      if (userId) {
         this.api.setUserId(userId);
      }

      if (contentSource) {
         this.api.setContentSource(contentSource);
      }

      if (apiUrl) {
         this.api.setApiUrl(apiUrl);
      }

      this.api.setClientShortName(clientShortName);

      await this.loadSettings();

      if (this.settings != null && this.settings.featureFlags != null) {
         this.featureFlags = this.settings.featureFlags;
      }

      if (!window.name) {
         window.name = Date.now().toString() + '_' + Math.floor(Math.random() * 10000000).toString();
      }

      const utmParams = this.getUtmInfo();
      const getVisitorParams = {};
      if (utmParams) {
         getVisitorParams['utm'] = utmParams;
      }
      if (window.document.referrer.length > 0) {
         getVisitorParams['referer'] = window.document.referrer;
      }

      let visitor_id = getCookie(visitorCookieName);
      let iframeMatched = getCookie(visitorMatchedCookieName);
      if (!visitor_id || visitor_id === "undefined" || iframeMatched === '' || iframeMatched !== 'true') {
         visitor_id = await this.getVisitorId(visitorCookieName, visitorMatchedCookieName, getVisitorParams, cookieDomain);
         this.visitorId = visitor_id;

         window.addEventListener('storage', (event) => {
            if (event.key === 'getVisitorId' && event.newValue === COMPLETED) {
               this.visitorId = this.getVisitorId(visitorCookieName, visitorMatchedCookieName, getVisitorParams, cookieDomain);
            }
            if (event.key === 'getVisitorId' && event.newValue === null) {
               this.visitorId = this.getVisitorId(visitorCookieName, visitorMatchedCookieName, getVisitorParams, cookieDomain);
            }
         });

         let timeoutID = setInterval(() => {
            if (window.localStorage.getVisitorId === COMPLETED) {
               clearTimeout(timeoutID);
            }
            if (window.localStorage.getVisitorId === WAITING) {
               if ((Math.round(Date.now() / 1000)).toString() - window.localStorage.getVisitorIdTimeout >= 5) {
                  window.localStorage.removeItem('getVisitorId');
                  this.visitorId = this.getVisitorId(visitorCookieName, getVisitorParams, cookieDomain);
               }
            }
         }, 1000);

         const pageReloaded = window.performance
            .getEntriesByType('navigation')
            .map((nav) => (nav.type))
            .includes('reload');

         window.onbeforeunload = function () {
            if (!pageReloaded) {
               window.localStorage.removeItem('getVisitorId');
            }
         };
      } else {
         this.visitorId = visitor_id;
         this.api.setVisitorId(this.visitorId);
      }
      this.setVisitorCookie(visitorCookieName, visitor_id, cookieDomain);

      this.setMethods(tracker);

      this.callPreloadQueue(preloadQueue, tracker);

      onLoad?.();

      if (this.featureFlags[feature.Sc16137]) {

         await this.loadPersonalizationData();
         this.featureFlags = this.personalizationData.featureFlags;

         if (this.featureFlags[feature.Sc15501]) {
            const sid = this.getSID();
            if (sid) {
               this.identifyBySid(sid, {
                  identified_by: 'link',
                  identifying_link: window.location.href,
               });
            }
         } else {
            this.api.setSID(this.getSID());
         }
      }

      let qs = new URLSearchParams(window.location.search);
      let widgetPreviewId = qs.get("hum_widget_preview")?.toString()
      if (widgetPreviewId && this.featureFlags[feature.Sc13756]) {
         try {
            await this.showWidgetPreview(widgetPreviewId);
         } catch (e) {
            console.log("Failed to preview widget:", e.message)
         }

         // the person is doing a widget preview, no need to do the rest of tracker operations
         return
      } else {
         this.page();
      }

      let campaign;
      if (this.featureFlags[feature.Sc13158]) {
         // Do campaign preview using shareable link field
         let shareableLinkId = qs.get("hum_magnet_preview")?.toString()
         if (shareableLinkId) {
            try {
               let shareableLinkData = await this.api.getCampaignPreview(shareableLinkId)
               campaign = JSON.parse(shareableLinkData["parameters"]);
               if (this.featureFlags[feature.Sc17695]) {
                  const url = this.removeHumMagnetPreviewParam(window.location.href);
                  const globallyExcludedPages = this.settings?.leadGenCampaigns?.excludedPages;
                  if (!magnetIsAllowedByPatterns(url, campaign.url_patterns, campaign.excluded_urls, globallyExcludedPages)) {
                     const showAnyway = await showPreviewMagnetWarning();
                     if (!showAnyway) {
                        return;
                     }
                  }
               }
               if (this.featureFlags[feature.Sc18399] && campaign?.form_data?.prompt_type === "inline") {
                  await verifyInlineCssSelector(campaign?.form_data?.prompt_css_selector);
               }
               if (!campaign && typeof campaign !== "object") {
                  campaign = undefined
               }
            } catch (e) {
               console.log("Failed to preview campaign:", e.message)
            }
         }
      } else {
         // Do campaign preview using anchor tag
         var anchorParams = window.location.href.split('#');
         let humPreviewString = "";
         if (anchorParams.length === 2 && anchorParams[1].startsWith('hum-preview')) {
            humPreviewString = anchorParams[1].replace("hum-preview=", "")
         }
         if (humPreviewString) {
            try {
               campaign = JSON.parse(decodeURI(humPreviewString));
               if (!campaign && typeof campaign !== "object") {
                  campaign = undefined
               }
            } catch (e) {
               console.log("Failed to preview campaign:", e.message)
            }
         }
      }

      // If the url has "hum-preview" campaign, it means that the person followed the link to view how the magnet campaign will look,
      // and there is no need to create a visitor and loading other campaigns that will restart the SweetAlert2
      if (campaign) {
         await showPreviewMagnetCampaign(campaign, this)
      } else {
         if (this.featureFlags[feature.Sc16137]) {
            this.campaigns.campaigns = this.settings.leadGenCampaigns.campaigns.filter(c => this.campaignIsValidForVisitor(c));
            this.campaigns.primaryInstitutionName = this.personalizationData.primaryInstitutionName;
            this.initAds();
            this.initMagnet();
         } else {
            if (visitor_id === undefined) {
               setTimeout(async () => {
                  await this.loadCampaigns();
               }, 2000);
            } else {
               await this.loadCampaigns();
            }
         }
      }

      if (this.featureFlags[feature.Sc16175]) {
         initBombora(this)
      }
   }

   campaignIsValidForVisitor(campaign) {
      return !this.personalizationData.completedCampaignCodes?.includes(campaign.code) && (!campaign.segment || this.personalizationData.segments.includes(campaign.segment));
   }

   initMagnet = () => {
      if (this.campaigns || this.featureFlags[feature.Sc16137]) {
         this.magnet = new Magnet(this);
      }
   }

   initAds = () => {
      try {
         new GoogleAdManager(this);
      } catch (e) {
         console.log('failed to init Ad Personalization');
      }
   }

   loadSettings = async () => {
      const expKey = this.api.clientShortName + '_settingsExpiresAt';
      try {
         const key = this.api.clientShortName + '_settings'
         this.settings = null;
         let settingsStr = localStorage.getItem(key);
         if (settingsStr && settingsStr.length > 0) {
            this.settings = JSON.parse(settingsStr);
         }
         if ((this.settings && isExpired(expKey) || !this.settings)) {
            this.settings = await this.api.getSettings('main');
            if (this.settings) {
               localStorage.setItem(key, JSON.stringify(this.settings));
               setExpirationAfter(expKey, this.settings.ttlSeconds);
            }
         }
      } catch (e) {
         console.warn('Failed to load settings', e.message);
      }
   }

   loadPersonalizationData = async () => {
      this.personalizationData = await this.api.getPersonalizationData(this.visitorId);
   }

   loadCampaigns = async () => {

      const expKey = this.api.clientShortName + '_cmpExpiresAt';
      if (!this.settings) {
         return;
      }
      try {
         const key = this.api.clientShortName + '_campaigns';
         this.campaigns = null;

         let campaignsStr = localStorage.getItem(key);
         if (campaignsStr && campaignsStr.length > 0) {
            this.campaigns = JSON.parse(campaignsStr);
         }
         if ((this.campaigns && isExpired(expKey)) || !this.campaigns) {
            this.campaigns = await this.api.getCampaigns(this.visitorId);
            if (this.campaigns) {

               localStorage.setItem(key, JSON.stringify(this.campaigns));
               setExpirationAfter(expKey, 300);
            }
         }
         this.initAds();
         this.initMagnet();
      } catch (e) {
         if (e.message === '503') {
            const retryAfter = 2000;
            setTimeout(this.loadCampaigns, retryAfter);
         }
         console.warn('Failed to load campaigns', e.message);
      }
   }

   inIframe() {
      try {
         return window.self !== window.top;
      } catch (e) {
         return true;
      }
   }

   isElementVisible(el, wrap) {
      wrap = wrap || document.body;
      const {top, bottom, height} = el.getBoundingClientRect();

      const wrapRect = wrap.getBoundingClientRect();

      return top <= wrapRect.top
         ? wrapRect.top - top <= height
         : bottom - wrapRect.bottom <= height;
   }

   isPointerEventInsideElement(event, element) {
      var pos = {
         x: event.targetTouches ? event.targetTouches[0].pageX : event.pageX,
         y: event.targetTouches ? event.targetTouches[0].pageY : event.pageY,
      };
      var rect = element.getBoundingClientRect();
      return pos.x < rect.right && pos.x > rect.left && pos.y < rect.bottom && pos.y > rect.top;
   }

   readElementProperties(element, elementProperties) {
      let properties = {};

      for (const [paramName, valueEl] of Object.entries(elementProperties.fields)) {

         if (valueEl.repeated && valueEl.class) {
            let values = [];

            element.querySelectorAll(valueEl.class).forEach(value => {
               values.push(value.textContent);
            });

            properties[paramName] = values;
            continue;
         }

         if (valueEl.link && valueEl.class) {
            properties[paramName] = element.querySelector(valueEl.class).href;
            continue;
         }

         if (valueEl.class) {
            properties[paramName] = element.querySelector(valueEl.class).textContent;
         }
      }

      return properties;
   }

   async page(properties) {
      //since page is the public method SPA's call, move applying of settings inside of it
      this.settings = await applySettings(this);

      if (!this.inIframe() && !this.settings?.doNotTrackPageViews) {
         this.pageHistory.trackPageHistory();
         const utmParams = this.getUtmInfo();
         if (utmParams) {
            properties = {...properties, ...utmParams};
         }

         if (this.eventsToSendWithPageView.length === 0) {
            this.api.sendEvent('pageview', properties);
         } else {
            let events = this.eventsToSendWithPageView;
            events.push('pageview');
            this.api.sendEvent(events, properties);
         }

         this.eventsToSendWithPageView = [];
      }
   }

   track(event, value, properties) {
      this.api.sendEvent(event, value, properties);
   }

   trackClick(elements, event, value, properties) {
      if (!elements) return;

      if (this.isElement(elements)) {
         elements = [elements];
      }
      if (!Array.isArray(elements)) {
         console.error('Must pass HTMLElement or Array of HTMLElement to trackClick');
         return;
      }
      elements.forEach((element) => {
         if (!this.isElement(element)) {
            console.error('Must pass HTMLElement to trackClick');
            return;
         }

         let elementProperties = properties || {};

         for (var i = 0; i < element.attributes.length; i++) {
            var attrib = element.attributes[i];
            elementProperties['attribute-' + attrib.name] = attrib.value;
         }

         element.addEventListener('click', () => this.api.sendEvent(event, value, elementProperties));
      });
   }

   trackLongHover(properties) {
      const elements = document.querySelectorAll(properties.post.class);
      if (!elements) {
         console.error('Must pass HTML element to trackScroll');
         return;
      }
      const self = this;
      elements.forEach(element => {
         let timer = null;

         element.addEventListener('mouseenter', () => {
            if (timer !== null) {
               clearTimeout(timer);
            }

            timer = setTimeout(() => {
               const elements = document.querySelectorAll(properties.postClass);
               if (!elements) {
                  console.error('Must pass HTML element to trackScroll');
                  return;
               }

               const elementProperties = self.readElementProperties(element, properties.post);
               self.api.sendEvent(properties.eventNames.hover, null, elementProperties);

            }, 1000);
         });
      });
   }

   trackLongClick(properties) {
      const wrapper = document.querySelector(properties.wrapClass);

      if (!wrapper) return;
      if (!this.isElement(wrapper)) {
         console.error('Must pass HTML elements\' wrap to trackScroll');
         return;
      }

      const self = this;
      wrapper.addEventListener('click', (e) => {
         const elements = document.querySelectorAll(properties.post.class);
         if (!elements) {
            console.error('Must pass HTML element to trackScroll');
            return;
         }

         elements.forEach(element => {
            if (self.isElementVisible(element, wrapper)) {
               if (self.isPointerEventInsideElement(e, element)) {
                  const elementProperties = self.readElementProperties(element, properties.post);

                  for (const [nameEl, valueEl] of Object.entries(properties.click)) {
                     if (e.target.classList.contains(valueEl.class.replace(/\./g, ''))) {
                        self.api.sendEvent(`${properties.eventNames.click} ${nameEl}`, null, elementProperties);
                     }
                  }

                  self.api.sendEvent(properties.eventNames.click, null, elementProperties);
               }
            }
         });
      });
   }

   sendWithPageView(event) {
      this.eventsToSendWithPageView.push(event);
   }

   trackScroll(element, event, value, properties) {
      if (!element) return;
      if (!this.isElement(element)) {
         console.error('Must pass HTMLElement to trackScroll');
         return;
      }
      const sent = [];
      //TODO: move to parameters
      const delay = 5000; //5 sec
      let startTimer;
      let midTimer;
      let endTimer;

      const self = this;
      const processPoint = function (rect, start, end, pointName, delay, timer) {
         if (sent.indexOf(pointName) === -1) {
            if (self.isElementPositionOnScreen(rect, start, end)) {
               if (!timer) {
                  timer = setTimeout(function () {
                     if (self.isElementPositionOnScreen(rect, start, end) && sent.indexOf(pointName) === -1) {
                        self.api.sendEvent(event + ' ' + pointName, value, properties);
                        sent.push(pointName);
                     }
                  }, delay);
               }
            } else {
               if (timer) {
                  clearTimeout(timer);
                  timer = null;
               }
            }
            return timer;
         }
         return null;
      };

      const scrollHandler = function () {
         const rect = element.getBoundingClientRect();
         startTimer = processPoint(rect, 0, 35, 'start', delay, startTimer);
         midTimer = processPoint(rect, 36, 70, 'mid', delay, midTimer);
         endTimer = processPoint(rect, 71, 100, 'end', delay, endTimer);
      };
      const debouncedHandler = debounce(500, scrollHandler);

      // Call to send event if element already visible
      scrollHandler();

      window.addEventListener('scroll', debouncedHandler);
   }

   trackLongScroll(properties) {
      const wrapper = document.querySelector(properties.wrapClass);

      if (!wrapper) return;
      if (!this.isElement(wrapper)) {
         console.error('Must pass HTML elements\' wrap to trackScroll');
         return;
      }

      const sent = [];
      //TODO: move to parameters
      const delay = 5000; //5 sec
      let startTimer;
      let midTimer;
      let endTimer;

      const self = this;
      const processPoint = function (rect, start, end, pointName, delay, timer, properties) {
         if (sent.indexOf(pointName) === -1) {
            if (self.isElementPositionOnScreen(rect, start, end)) {
               if (!timer) {
                  timer = setTimeout(function () {
                     if (self.isElementPositionOnScreen(rect, start, end) && sent.indexOf(pointName) === -1) {
                        self.api.sendEvent(`${properties.eventNames.scroll} ${pointName}`, null, properties);
                        sent.push(pointName);
                     }
                  }, delay);
               }
            } else {
               if (timer) {
                  clearTimeout(timer);
                  timer = null;
               }
            }
            return timer;
         }
         return null;
      };

      let timer = null;
      const scrollHandler = () => {
         if (timer !== null) {
            clearTimeout(timer);
         }
         timer = setTimeout(() => {
            const elements = document.querySelectorAll(properties.postClass);
            if (!elements) {
               console.error('Must pass HTML element to trackScroll');
               return;
            }

            elements.forEach(element => {
               if (self.isElementVisible(element, wrapper)) {
                  const rect = element.getBoundingClientRect();

                  const elementProperties = self.readElementProperties(element, properties.post);

                  startTimer = processPoint(rect, 0, 35, 'start', delay, startTimer, elementProperties);
                  midTimer = processPoint(rect, 36, 70, 'mid', delay, midTimer, elementProperties);
                  endTimer = processPoint(rect, 71, 100, 'end', delay, endTimer, elementProperties);
               }
            });


            self.trackLongHover(properties);

         }, 150);
      };
      const debouncedHandler = debounce(500, scrollHandler);

      // Call to send event if element already visible
      scrollHandler();

      wrapper.addEventListener('scroll', debouncedHandler);
   }

   trackView(element, event, value, properties) {
      if (!element) return;

      if (!this.isElement(element)) {
         console.error('Must pass HTMLElement to trackView');
         return;
      }

      if (this.isVisibleForUser(element)) {
         this.api.sendEvent(event, value, properties);
         return;
      }

      const self = this;
      const handler = function () {
         if (self.isVisibleForUser(element)) {
            self.api.sendEvent(event, value, properties);
            removeListeners();
         }
      };
      const debouncedHandler = debounce(1000, handler);
      const removeListeners = function () {
         window.removeEventListener('scroll', debouncedHandler);
         window.removeEventListener('resize', debouncedHandler);
      };
      window.addEventListener('scroll', debouncedHandler);
      window.addEventListener('resize', debouncedHandler);
   }

   getRecommendationWidgetContentIds(recWidget) {
      return Array.from(recWidget.querySelectorAll('a.hum-recommendations-row')).reduce((dataContentIds, aTag) => {
         const dataContentId = aTag.dataset.contentId;
         if (dataContentId) {
            dataContentIds.push(dataContentId);
         }
         return dataContentIds;
      }, []);
   }

   trackRecommendationView(recWidget, listId) {
      const debouncedHandler = debounce(1000, () => {
         if (this.isVisibleForUser(recWidget) && !document.hidden) {
            const eventName = 'recommendation-viewable';
            const eventData = {
               widget_id: listId,
               recommended_content: this.getRecommendationWidgetContentIds(recWidget),
            };
            this.api.sendEvent(eventName, eventData);
            removeListeners();
         }
      });
      const removeListeners = function () {
         window.removeEventListener('scroll', debouncedHandler);
         window.removeEventListener('resize', debouncedHandler);
      };

      if (this.isVisibleForUser(recWidget) && !document.hidden) {
         const eventName = 'recommendation-viewable';
         const eventData = {
            widget_id: listId,
            recommended_content: this.getRecommendationWidgetContentIds(recWidget),
         };
         this.api.sendEvent(eventName, eventData);
      }

      window.addEventListener('scroll', debouncedHandler);
      window.addEventListener('resize', debouncedHandler);
   }

   trackRecommendationClick(recWidget, listId) {
      recWidget?.querySelectorAll('a.hum-recommendations-row')?.forEach((aTag) => {
         aTag.addEventListener('click', () => {
            const eventName = 'recommendation-click';
            const dataContentId = aTag.dataset.contentId;
            const eventData = {
               widget_id: listId,
               content_clicked: dataContentId,
               recommended_content: this.getRecommendationWidgetContentIds(recWidget),
            };

            this.api.sendEvent(eventName, eventData);
         });
      })
   }

   identify(userId, properties) {
      this.api.setUserId(userId);
      if (userId) {
         this.api.sendEvent('Identify', properties);
      } else {
         //TODO find better name
         this.api.sendEvent('User clear', properties);
      }
   }

   identifyBySid(sId, properties) {
      this.api.setSID(sId);
      if (sId) {
         this.api.sendEvent('Identify', properties);
      }
   }

   getFormListener(humForm, $formElement) {
      let listener = this.formSubmitListeners[humForm.humFormId];

      if (!listener) {
         listener = e => {
            let formEl;
            if (e.target.tagName === 'FORM') {
               if (!e.target.checkValidity()) {
                  return;
               }
               formEl = e.target;
            } else {
               let validity = true;
               $formElement.querySelectorAll('input').forEach(el => {
                  validity = validity && el.checkValidity();
               });
               if (!validity) {
                  return;
               }
               formEl = $formElement;
            }
            this.processFormData(humForm, formEl);

         };
         this.formSubmitListeners[humForm.humFormId] = listener;
      }

      return listener;
   }

   processFormData(humForm, formElement) {
      const emails = [];
      const data = {};
      data.form_id = humForm.humFormId;

      let sidElement = formElement.querySelector(humForm.sidSelector);
      if (sidElement && sidElement.value) {
         data.sid = (humForm.sidPrefix || '') + sidElement.value.trim();
      }

      const emailSelector = humForm.emailSelector || 'input[type="email"]';
      let emailElements = formElement.querySelectorAll(emailSelector);
      if (emailElements) {
         Array.from(emailElements).forEach(emailEl => {
            const email = emailEl.value.trim();
            if (emailRe.test(String(email).toLowerCase())) {
               emails.push(email);
               if (emails.length === 1) {
                  delete data.sid; // remove sid when form contains a valid email
               }
            }
         });
      }

      if (humForm.singleValueFieldSelectors) {
         const svfsKeys = Object.getOwnPropertyNames(humForm.singleValueFieldSelectors);
         svfsKeys.forEach(key => {
            let element = formElement.querySelector(humForm.singleValueFieldSelectors[key]);
            if (element) {
               let val = formElement.querySelector(humForm.singleValueFieldSelectors[key]).value;
               data[key] = val.trim().substring(0, MAX_LENGTH);
            }
         });
      }

      if (humForm.multiValueFieldSelectors) {
         const mvfsKeys = Object.getOwnPropertyNames(humForm.multiValueFieldSelectors);
         mvfsKeys.forEach(key => {
            data[key] = [
               ...formElement.querySelectorAll(humForm.multiValueFieldSelectors[key]),
            ].map(c => c.value.trim().substring(0, MAX_LENGTH));
         });
      }

      if (humForm.trueIfExistsFieldSelectors) {
         const trueKeys = Object.getOwnPropertyNames(humForm.trueIfExistsFieldSelectors);
         trueKeys.forEach(key => {
            let element = formElement.querySelector(humForm.trueIfExistsFieldSelectors[key]);
            if (element) {
               data[key] = 'true';
            }
         });
      }

      if (this.featureFlags[feature.Sc15501]) {
         if (emailElements && emailElements.length === 1 && emails.length === 1) {
            this.identify(emails[0], {
               identified_by: 'form',
               identifying_form: data.form_id,
            });
         } else if (data?.sid) {
            this.identifyBySid(data.sid, {
               identified_by: 'form',
               identifying_form: data.form_id,
            });
         }
      } else {
         if (emailElements && emailElements.length === 1 && emails.length === 1) {
            this.identify(emails[0]);
         }
      }

      if (humForm.eventType) {
         this.track(humForm.eventType, emails[0], data);
      }

   }

   isElement(element) {
      return element instanceof Element;
   }

   isElementPositionOnScreen(elementRect, start, end) {
      const currentStart = -1 * elementRect.top;
      const currentEnd = -1 * elementRect.top + window.innerHeight;
      const startPos = (currentStart / elementRect.height) * 100;
      const endPos = (currentEnd / elementRect.height) * 100;

      return (start <= endPos && start >= startPos)
         || (end <= endPos && end >= startPos)
         || (startPos <= end && startPos >= start)
         || (endPos <= end && endPos >= start);
   }

   getUtmInfo() {
      const source = this.getParameterByName('utm_source');
      const medium = this.getParameterByName('utm_medium');
      const campaign = this.getParameterByName('utm_campaign');
      const term = this.getParameterByName('utm_term');
      const content = this.getParameterByName('utm_content');
      if (source || medium || campaign || term || content) {
         return {
            utm_source: source,
            utm_medium: medium,
            utm_campaign: campaign,
            utm_term: term,
            utm_content: content,
         };
      }
      return false;
   }

   getSID() {
      let id = this.getParameterByName('external_id');

      if (!id) {
         id = this.getParameterByName('nbd');
      }

      let source = this.getParameterByName('external_id_source');
      if (!source) {
         source = this.getParameterByName('nbd_source');
      }

      if (id && source) {
         return `${source}_${id}`;
      }

      return '';
   }

   getParameterByName(name, url) {
      if (!url) url = window.location.href;
      name = name.replace(/[\[\]]/g, '\\$&');
      const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
      const results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      const val = results[2].replace(/\+/g, ' ');
      try {
         return decodeURIComponent(val);
      } catch (e) {
         console.warn(e);
         return val;
      }
   }

   setVisitorCookie(cookieName, visitor_id, cookieDomain) {
      const visitorCookieOpts = {
         expires: 20 * 365 * 24 * 60 * 60, // 20 yrs
         path: '/',
         domain: cookieDomain,
         SameSite: 'None',
         Secure: true,
      };
      setCookie(cookieName, visitor_id, visitorCookieOpts);

   }

   async getVisitorId(cookieName, visitorMatchedCookieName, params, cookieDomain) {
      let visitor_id = getCookie(cookieName);
      const iframeUrl = this.settings?.iframeUrl ?? '';
      let newVisitor = false;
      const customAttributesFlag = this.settings?.iframeCustomAttrEnabled;
      const iframeAttributes = this.settings?.iframeCustomAttributes ?? {};

      if (!visitor_id || visitor_id === 'undefined') {
            window.localStorage.setItem('getVisitorId', WAITING);
            window.localStorage.setItem('getVisitorIdTimeout', (Math.round(Date.now() / 1000)).toString());
            visitor_id = await iframe.getVisitorId(iframeUrl, cookieName, customAttributesFlag, iframeAttributes);
         if (!visitor_id) {
            newVisitor = true;
            visitor_id = crypto.randomUUID();
            const res = await iframe.setVisitorId(visitor_id, iframeUrl, cookieName, customAttributesFlag, iframeAttributes);
            if (!res) {
               console.warn('failed set a visitor_id to the iframe LS');
            }
         }
      }
      this.setVisitorCookie(cookieName, visitor_id, cookieDomain);

      const iframe_visitor_id = await iframe.getVisitorId(iframeUrl, cookieName, customAttributesFlag, iframeAttributes);
      if (typeof iframe_visitor_id !== 'string') {
         console.warn('ifr visId not present');
         window.localStorage.setItem('getVisitorId', COMPLETED);
         this.api.setVisitorId(visitor_id);
         return visitor_id;
      }
      if (iframe_visitor_id && visitor_id !== iframe_visitor_id) {
         try {
            if (!newVisitor) {
              if (this.featureFlags[feature.Sc10075]) {
                  await this.api.sendVisitorMergeEvent(visitor_id, iframe_visitor_id);
               } else {
                  await this.api.mergeVisitorsById(visitor_id, iframe_visitor_id);
               }
            }
            this.setVisitorCookie(cookieName, iframe_visitor_id, cookieDomain);
            this.setVisitorMatchedCookie(visitorMatchedCookieName, cookieDomain);
            window.localStorage.setItem('getVisitorId', COMPLETED);
            this.api.setVisitorId(iframe_visitor_id);
            return iframe_visitor_id;
         } catch (e) {
            if (e.message.indexOf('source visitor: elastic: Error 404') !== -1) {
               this.setVisitorCookie(cookieName, iframe_visitor_id, cookieDomain);
               this.api.setVisitorId(iframe_visitor_id);
               return iframe_visitor_id;
            }
            console.error(`failed to merge visitorIDs src ${visitor_id} dst ${iframe_visitor_id}: ${e.message}`);
         }
      } else {
         this.setVisitorMatchedCookie(visitorMatchedCookieName, cookieDomain);
      }
      window.localStorage.setItem('getVisitorId', COMPLETED);
      this.api.setVisitorId(visitor_id);
      return visitor_id;
   }

   //set a cookie noting that we have matched the visitor cookie to the iframe cookie
   setVisitorMatchedCookie(visitorMatchedCookieName, cookieDomain) {
      const visitorMatchedCookieOpts = {
         expires: 400 * 24 * 60 * 60, // 400 days
         path: '/',
         domain: cookieDomain,
         SameSite: 'None',
         Secure: true,
      };
      setCookie(visitorMatchedCookieName, 'true', visitorMatchedCookieOpts)

   }

   async showWidgetPreview(widgetPreviewId) {
      const widgetData = await this.api.getWidgetPreview(widgetPreviewId);
      const markup = widgetData["markup"];
      if (!markup) {
         throw new Error(`Request for 'WidgetPreview' returned no markup`);
      }
      const css_selector = widgetData["css_selector"];
      if (!css_selector) {
         throw new Error(`Request for 'WidgetPreview' returned no css_selector`);
      }

      let $widgetElements = document.querySelectorAll(css_selector);
      if ($widgetElements.length > 0) {
         $widgetElements.forEach($widgetEl => {
            const placeholderElement = document.createElement('div');
            placeholderElement.innerHTML = markup;
            $widgetEl.appendChild(placeholderElement);
         });
      } else {
         Swal.fire('The CSS Selector you have input was not matched', '', 'error');
      }
   }

   isVisibleForUser(el) {
      const rect = el.getBoundingClientRect();
      const elemTop = rect.top;
      const elemBottom = rect.bottom;

      // Only completely visible elements return true:
      return (elemTop >= 0) && (elemBottom <= window.innerHeight);
      // Partially visible elements return true:
      // return elemTop < window.innerHeight && elemBottom >= 0
   }

   /** @param {PreloadQueueItem[]} queue
    *  @param tracker
    * */
   callPreloadQueue(queue, tracker) {
      queue.filter(({method}) => tracker.methods.has(method))
         .forEach(({method, args}) => tracker[method](...args));
   }

   disablePopups() {
      this.popupsAreAllowed = false;
   }

   arePopupsAllowed() {
      return this.popupsAreAllowed
   }

   setMethods(tracker) {
      tracker.methods = new Set([
         'page', 'track', 'trackClick', 'trackLongClick', 'trackScroll', 'trackLongScroll', 'trackView', 'identify',
         'identifyBySid', 'trackLongHover', 'trackRecommendationView', 'trackRecommendationClick',
         'handleRecommendationView'
      ]);

      tracker.page = this.page.bind(this);
      tracker.track = this.track.bind(this);
      tracker.trackClick = this.trackClick.bind(this);
      tracker.trackLongClick = this.trackLongClick.bind(this);
      tracker.trackScroll = this.trackScroll.bind(this);
      tracker.trackLongScroll = this.trackLongScroll.bind(this);
      tracker.trackView = this.trackView.bind(this);
      tracker.identify = this.identify.bind(this);
      tracker.identifyBySid = this.identifyBySid.bind(this);
      tracker.getFormListener = this.getFormListener.bind(this);
      tracker.trackLongHover = this.trackLongHover.bind(this);
      tracker.disablePopups = this.disablePopups.bind(this);
      tracker.arePopupsAllowed = this.arePopupsAllowed.bind(this)
      tracker.trackRecommendationView = this.trackRecommendationView.bind(this)
      tracker.trackRecommendationClick = this.trackRecommendationClick.bind(this)
      tracker.sendWithPageView = this.sendWithPageView.bind(this)
   }

   async delay(fn) {
      return new Promise(resolve => setTimeout(
         async () => resolve(await fn()),
         this.visitorReqDelay,
      ));
   }

   removeHumMagnetPreviewParam(url) {
      const urlObj = new URL(url);
      const params = urlObj.searchParams;

      params.delete('hum_magnet_preview');

      return urlObj.origin + urlObj.pathname + (params.toString() ? '?' + params.toString() : '');
   }
}
